import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRecurringById } from "../actions";
import { toastAlert } from "@sellix/shared/components";
import { Info, Invoices, Header, Custom } from "./components";
import { Button } from "@sellix/shared/components";
import Breadcrumbs from "../../../components/breadcrumbs";
import CancelModal from "./modals/cancelModal";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo, IResponse, ISubscription, ISubscriptionWrapper } from "../../../types/types";
import { ICustomFields } from "../../invoices/details/components/custom";
import { Allowance } from "../../invoices/details/components";
import CancelEVM from "./cancel-evm";

import "./index.css";
import "../index.css";


const SubscriptionDetails = () => {

  const [cancelEVMModal, showCancelEVMModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bill, setBill] = useState<ISubscription>();
  const [openCloseModal, setCloseModal] = useState(false);
  const [invoices, setInvoices] = useState<IInvoiceInfo[]>([]);
  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details" });

  const { id } = useParams();
  const navigate = useNavigate();
  const billId = (bill?.type === "v2" ? bill.product_subscription_id : bill?.id) || id;

  const initializeData = useCallback(() => {
    setLoading(true);
    getRecurringById(id)
      .then((res: IResponse<ISubscriptionWrapper>) => {
        const { recurring_billing } = res.data;
        if(recurring_billing) {
          setInvoices(recurring_billing.invoices);
          setBill(recurring_billing);
        }
      })
      .catch((err) => {
        toastAlert("error", err ? err.error : "")
        navigate("/subscriptions");
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id, navigate]);

  useEffect(() => {
    initializeData();
  }, [initializeData])


  if(loading || !bill) {
    return null;
  }

  let custom: ICustomFields[] = [];
  if (bill.custom_fields) {
    custom = Object.keys(bill.custom_fields)
      .map((value: string) => ({
        field: value,
        value: bill.custom_fields?.[value]
      }));
  }

  return (
    <div className="invoice-details mb-0">

      <div className="screen-header pt-0">
        <Breadcrumbs
          title={bill.product_title}
          breadcrumbs={[
            { title: t('breadcrumbs'), link: '/subscriptions' },
            { title: `${billId}`, link: `/subscriptions/${id}` },
          ]} />

        <div className="screen-filters gap-2">
          {!["AWAIT_CANCELING", "CANCELED", "COMPLETED"].includes(bill.status)  &&
            <Button variant="warning" size="large" onClick={(): void => setCloseModal(true)}>
              {t('cancelButton')}
            </Button>
          }
          {bill.approved_address &&
              <Button variant="warning" size="large" onClick={(): void => showCancelEVMModal(true)}>
                  Reset EVM amount
              </Button>
          }

          {bill.approved_address && <CancelEVM initializeData={initializeData} bill={bill} cancelEVMModal={cancelEVMModal} showCancelEVMModal={showCancelEVMModal}  />}
          <CancelModal
            initializeData={initializeData}
            openModal={openCloseModal}
            closeModal={(): void => setCloseModal(false)}
            isArrears={bill.product_plan_payment_type === "ARREARS"}
          />
        </div>
      </div>

      <div className="invoice-details-header">
        <Header tick={invoices.length} bill={bill} />
      </div>

      <Invoices invoices={invoices} />

      <div className="invoice-details-body">
        <div className={`invoice-details-body-left ${!custom.length ? "w-full" : ""}`}>
          <Info tick={invoices.length} bill={bill} />
        </div>

        {(custom.length || bill.approved_address) ? <div className="invoice-details-body-right">
          {custom.length ? <Custom custom={custom} /> : null}
          <Allowance allowance={bill.approved_address} />
        </div> : null}
      </div>

    </div>
  )
}


export default SubscriptionDetails;
