import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@sellix/shared/components";
import { ChevronRightIcon, CopyIcon } from "../../assets/icons";

import "./style.css";

export interface IBreadcrumbs {
    title: string
    copy?: boolean
    tip?: boolean
    className?: string
    breadcrumbs?: {
        title: string
        link: string
    }[]
}

const Breadcrumbs = ({ title, copy, className, breadcrumbs, tip }: IBreadcrumbs): ReactNode => {

  return (
    <div className={`breadcrumbs-container ${className ? className : ''}`}>

      <div className="breadcrumbs">
        {breadcrumbs?.map(({ title, link }, index) => (
          <React.Fragment key={index}>
            <Link className="link" to={link}>
              {breadcrumbs.length - 1 !== index ? title : title}
            </Link>
            {breadcrumbs.length - 1 !== index && (
              <ChevronRightIcon className="" style={{ width: 14, height: 14 }} />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="title">
        <h1 className="title">{title}</h1>
        {tip && <Tooltip name={`any-${tip}`} tooltipText="Copy ID">{tip}</Tooltip>}
        {!tip && copy &&
            <Tooltip name="title" onlyCopyIcon tooltipText={title} successText="Invoice ID copied.">
              <CopyIcon />
            </Tooltip>
        }
      </div>

    </div>
  );
};

export default Breadcrumbs;
