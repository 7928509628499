import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Button, toastAlert } from "@sellix/shared/components";
import { TrashIcon } from "@sellix/shared/components";
import useMobile from "../../hooks/useMobile.ts";
import { IPaymentMethod } from "../../types/types.ts";
import {
  getPaymentMethods as getPaymentMethodsAction,
  deletePaymentMethod as deletePaymentMethodAction,
} from "./actions.ts";
import Empty from "../../components/empty";
import { PaymentMethodsIcon, PureFileIcon } from "../../assets/icons.tsx";
import { random } from "../../utils/config.tsx";
import Table from "../../components/table";

import { MobilePlaceholder } from "./mobile.tsx";
import { ICONS, TITLES } from "./constants.ts";
import MobilePaymentMethods from "./mobile.tsx"

import "./index.css";
import { useNavigate } from "react-router-dom";

const PaymentMethods = () => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const { t } = useTranslation("translation", { keyPrefix: "customer.paymentMethods" });
  const isMobile = useMobile();
  const navigate = useNavigate();

  const getPaymentMethods = useCallback(async() => {
    try {
      setLoading(true);
      const res = await getPaymentMethodsAction();
      const paymentMethods = res.data.payment_methods ?? [];
      setPaymentMethods(paymentMethods);
    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [setPaymentMethods, setLoading]);

  const handleDeletePaymentMethod = useCallback(async(id: number) => {
    if (deleting) {
      return;
    }
    try {
      setDeleting(true);
      const deleteRes = await deletePaymentMethodAction(id);
      toastAlert("success", deleteRes.message || t("phrases.addPaymentMethodSuccess"));

      const refreshRes = await getPaymentMethodsAction();
      const paymentMethods = refreshRes.data.payment_methods ?? [];
      setPaymentMethods(paymentMethods);
    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setDeleting(false);
    }
  }, [deleting, setDeleting]);

  const handleAddPaymentMethod = useCallback(() => {
    navigate('payment-methods/create');
  }, [navigate]);

  useEffect(() => {
    getPaymentMethods().catch(() => {});
  }, [getPaymentMethods]);

  const placeholder = useMemo(() => (
    [
      {
        accessorKey: "title",
        header: t("table.columns.title"),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }} />
      }, {
        accessorKey: "gateway",
        header: t("table.columns.gateway"),
        enableSorting: false,
        cell: () => <div>
          <div className="payment-method-type">
            <div className="payment-method-type-icon">
              <PureFileIcon/>
            </div>
            <div className="payment-method-type-title">
              <div className="skeleton skeleton2 small" style={{ width: random(80, 120) }}/>
            </div>
          </div>
        </div>
      }, {
        accessorKey: "status",
        header: t("table.columns.status"),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }} />
      }, {
        accessorKey: "last_confirm_date",
        header: t('table.columns.lastConfirmDate'),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }} />
      }, {
        accessorKey: "actions",
        header: "",
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(70) }}/>,
      }
    ]
  ), [t]);

  const columns: ColumnDef<IPaymentMethod>[] = useMemo(() => [
    {
      accessorKey: "title",
      header: t("table.columns.title") as string,
      enableSorting: false,
    }, {
      accessorKey: "gateway",
      header: t("table.columns.gateway") as string,
      enableSorting: false,
      cell: ({ row }: CellContext<IPaymentMethod, unknown>) => {
        const Icon = ICONS[row.original.gateway as keyof typeof ICONS];
        const title = TITLES[row.original.gateway as keyof typeof TITLES];
        return <>
          <div className="payment-method-type">
            <div className="payment-method-type-icon">
              <Icon />
            </div>
            <div className="payment-method-type-title">
              {title}
            </div>
          </div>
        </>
      }
    }, {
      accessorKey: "status",
      header: t("table.columns.status") as string,
      enableSorting: false,
      cell: ({ row }) => {
        const isActive = row.original.status === "ACTIVE";
        const isInactive = row.original.status === "INACTIVE";

        return <div className="flex gap-2">
          <div
            className={`badge ${isActive && "badge-success"} ${isInactive && "badge-failed"}`}>
            {isActive && t("status.active")}
            {isInactive && t("status.inactive")}
          </div>
        </div>
      }
    }, {
      accessorKey: "last_confirm_date",
      header: t("table.columns.lastConfirmDate") as string,
      enableSorting: false,
      cell: ({ row }: CellContext<IPaymentMethod, unknown>) => {
        return row.original.last_confirm_date
          ? moment(row.original.last_confirm_date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY (HH:mm)")
          : null;
      }
    }, {
      accessorKey: "actions",
      header: '',
      enableSorting: false,
      cell: ({ row }: CellContext<IPaymentMethod, unknown>) => {
        return <div className="flex w-full justify-end">
          <div className="payment-method-delete-btn" onClick={() => handleDeletePaymentMethod(row.original.id)}>
            <TrashIcon />
          </div>
        </div>;
      }
    }
  ], [handleDeletePaymentMethod, t]);

  return (
    <div className="payment-methods-screen mt-8 mb-8">

      <div className="flex justify-between items-center">
        <div style={{ fontSize: 20, fontWeight: 600, color: "var(--black9)" }} className="flex gap-4 items-center">
          {t("title")}

          <span className="badge badge-sellix" data-tooltip-id="beta">
                Beta
          </span>
        </div>
        <Button
          className="flex gap-2"
          onClick={handleAddPaymentMethod}
        >
          <PaymentMethodsIcon/> {t('buttons.addNewButton')}
        </Button>

      </div>

      {!loading && !paymentMethods.length && <Empty isPaymentMethods={true}/>}

      {(loading || !!paymentMethods.length) && (
        <div className="payment-methods-screen-container">
          {loading && isMobile && <MobilePlaceholder/>}
          {loading && !isMobile && <Table data={[{}, {}, {}, {}]} isPlaceholder columns={placeholder}/>}

          {!loading && !!paymentMethods.length && isMobile && (
            <MobilePaymentMethods data={paymentMethods} deletePaymentMethod={handleDeletePaymentMethod}/>
          )}

          {!loading && !!paymentMethods.length && !isMobile && (
            <Table cursorPointer data={paymentMethods} columns={columns}/>
          )}
        </div>
      )}
    </div>
  );
}

export default PaymentMethods;