import { useEffect, useState } from "react";


export const darkInitialStyles = {
  "border-radius": "8px",
  "modal-border-radius": "16px",

  "secondary-background-color": "#121417",
  "main-background-color": "#1B1F23",
  "main-background-gradient-color": "#1B1F23",

  "border-color": "#262A30",
  "border-width": "1px",
  "primary-font-color": "#ffffff",
  "secondary-font-color": "#777F89",

  "brand-color": "#6a3ce2",

  "button-border-color": "#121417",
  "button-border-width": "0",
  "button-background": "#ffffff",
  "button-radius": "8px",
  "button-font-color": "#121417",
  "button-font-weight": "600",

  "button-secondary-border-color": "#262A30",
  "button-secondary-border-width": "1px",
  "button-secondary-background": "#ffffff00",
  "button-secondary-radius": "8px",
  "button-secondary-font-color": "#777F89",
  "button-secondary-font-weight": "500",


  "input-border-width": "1px",
  "input-border-color": "#262A30",
  "input-background": "#1B1F23",
  "input-radius": "8px",
  "input-font-color": "#ffffff",
  "input-font-weight": "400",
};

export const lightInitialStyles = {
  "border-radius": "8px",
  "modal-border-radius": "16px",

  "border-color": "#DFE5E9",
  "border-width": "1px",

  "secondary-background-color": "#F5F7F8",
  "main-background-color": "#ffffff",
  "main-background-gradient-color": "#ffffff",
  "primary-font-color": "#121417",
  "secondary-font-color": "#555D67",

  "brand-color": "#6a3ce2",

  "button-border-width": "0px",
  "button-border-color": "#121417",
  "button-radius": "8px",
  "button-background": "#121417",
  "button-font-color": "#ffffff",
  "button-font-weight": "600",

  "button-secondary-border-color": "#DFE5E9",
  "button-secondary-border-width": "1px",
  "button-secondary-background": "#ffffff00",
  "button-secondary-radius": "8px",
  "button-secondary-font-color": "#555D67",
  "button-secondary-font-weight": "500",


  "input-border-width": "1px",
  "input-border-color": "#DFE5E9",
  "input-background": "#ffffff",
  "input-radius": "8px",
  "input-font-color": "#121417",
  "input-font-weight": "400",
}

export const getStyles = (font: any, styles: any, css: any) => {

  const hexToRGBString = (hex: any) => {
    hex = hex.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgb(${r} ${g} ${b})`;
  }

  const hexToRGBObject = (hex: any) => {
    hex = hex.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  }

  const darkenRGBColor = (hex: any, percent: any) => {
    const rgb = hexToRGBObject(hex);

    const r = Math.round(rgb.r * (1 - percent / 100));
    const g = Math.round(rgb.g * (1 - percent / 100));
    const b = Math.round(rgb.b * (1 - percent / 100));

    return `rgb(${r} ${g} ${b})`;
  }

  const highOpacityRGBColor = (hex: any, percent: any) => {
    const rgb = hexToRGBObject(hex);

    const r = rgb.r;
    const g = rgb.g;
    const b = rgb.b;

    return `rgb(${r} ${g} ${b} / ${percent}%)`;
  }

  if (styles?.["main-background-color"]) {
    return `html {
                    * {
                        font-family: '${font?.name || "Inter"}', 'Inter', sans-serif !important;       
                    }
                    input.sellix-input {
                        font-family: '${font?.name || "Inter"}', 'Inter', sans-serif !important;       
                    }
                    textarea.sellix-input {
                        font-family: '${font?.name || "Inter"}', 'Inter', sans-serif !important;       
                    }
                    select.sellix-select {
                        font-family: '${font?.name || "Inter"}', 'Inter', sans-serif !important;       
                    }
                    input, select, .embed-counter-input {
                        font-family: '${font?.name || "Inter"}', 'Inter', sans-serif !important;       
                    }
                    ${styles['hide-shadow'] ? `
                        * {
                            box-shadow: none !important;       
                        }
                    ` : ''
}
                
                ${Object.entries(styles).map(([key, value]) => `--${key}: ${value};`).join(' ')}
                --hidden-content-shadow: ${hexToRGBString(styles["main-background-color"])};
                --hidden-main-content-shadow: ${hexToRGBString(styles["secondary-background-color"])};
                --secondary-background-hover: ${darkenRGBColor(styles["secondary-background-color"], 10)};
                --skeleton-background: ${darkenRGBColor(styles["secondary-background-color"], 30)};
                --button-background-hover: ${darkenRGBColor(styles["button-background"], 10)};
                --brand-hover-background: ${highOpacityRGBColor(styles["brand-color"], 10)};
                
                ${css || ''}
            }`;
  } else {
    return ``;
  }
}

const useCustomTheme = ({ theme, isDark, getShopInfo }: any) => {

  const [customerMainPage, setCustomerMainPage] = useState(false);
  const [hideCustomerCommissions, setHideCustomerCommissions] = useState(false);
  const [hideCustomerAffiliate, setHideCustomerAffiliate] = useState(false);
  const [hideCustomerRewards, setHideCustomerRewards] = useState(false);
  const [hideCustomerSubscriptions, setHideCustomerSubscriptions] = useState(false);
  const [hideCustomerLicenses, setHideCustomerLicenses] = useState(false);
  const [hideCustomerGaming, setHideCustomerGaming] = useState(false);

  const [css, setCSS] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [customerPortalDomain, setCustomerPortalDomain] = useState("");
  const [withGradient, setWithGradient] = useState(false);
  const isCustomization = window.location.search.includes('CUSTOMIZATION');
  const isCustom = isCustomization ? true : theme;

  useEffect(() => {
    if (theme?.configuration) {

      if (theme.configuration?.title) {
        document.title = `${theme.configuration.title}`;
      }

      if (theme.configuration?.businessName) {
        setBusinessName(theme.configuration.businessName);
      }

      if (theme.configuration?.customerMainPage) {
        setCustomerMainPage(theme.configuration.customerMainPage);
      }

      if (theme.configuration?.hideCustomerAffiliate) {
        setHideCustomerAffiliate(theme.configuration.hideCustomerAffiliate);
      }
      if (theme.configuration?.hideCustomerCommissions) {
        setHideCustomerCommissions(theme.configuration.hideCustomerCommissions);
      }
      if (theme.configuration?.hideCustomerRewards) {
        setHideCustomerRewards(theme.configuration.hideCustomerRewards);
      }
      if (theme.configuration?.hideCustomerSubscriptions) {
        setHideCustomerSubscriptions(theme.configuration.hideCustomerSubscriptions);
      }
      if (theme.configuration?.hideCustomerLicenses) {
        setHideCustomerLicenses(theme.configuration.hideCustomerLicenses);
      }
      if (theme.configuration?.hideCustomerGaming) {
        setHideCustomerGaming(theme.configuration.hideCustomerGaming);
      }

      if (theme.configuration?.customerPortalDomain) {
        setCustomerPortalDomain(theme.configuration.customerPortalDomain);
      } else {
        setCustomerPortalDomain("");
      }

      if (theme.configuration?.styles) {
        setWithGradient(theme.configuration.styles?.["main-background-gradient-color"]?.includes("gradient"));
      }

      if (theme.configuration.css) {
        setCSS(theme.configuration.css)
      }

      document.documentElement.classList.remove("dark");
      document.documentElement.classList.remove("light");
      document.documentElement.classList.remove("custom");
      document.documentElement.classList.add("custom");

      const styles = getStyles(theme.configuration.font, theme.configuration.styles, theme.configuration.css);

      if (document.getElementById("style-center-styles")) {
        // @ts-ignore
        document.getElementById("style-center-styles").textContent = styles;
      } else {
        const style = document.createElement('style');
        style.id = "style-center-styles";
        style.textContent = styles;
        document.head.appendChild(style);
      }

      if (theme.configuration.font?.link && theme.configuration.font?.link.startsWith("https://fonts.googleapis.com/")) {
        const link = document.createElement('link');

        link.href = theme.configuration.font.link;
        link.rel = "stylesheet";
        document.head.appendChild(link);
      }

    }
  }, [theme]);

  useEffect(() => {

    if (isCustomization) {
      window.addEventListener('message', (event: any) => {

        if (event.data.type === 'applyCSS') {
          setCSS(event.data.css);

          if (document.getElementById("style-center-styles")) {
            const style = document.getElementById("style-center-styles");
            // @ts-ignore
            style.textContent = style?.textContent + event.data.css;
          } else {
            const style = document.createElement('style');
            style.id = "style-center-styles";
            style.textContent = style.textContent + event.data.css;
            document.head.appendChild(style);
          }
        }

        if (event.data.type === 'applyStyles') {
          const style = document.createElement('style');
          style.textContent = event.data.styles;

          if (event.data?.styles) {
            setWithGradient(event.data.styles.includes("main-background-gradient-color: linear") || event.data.styles.includes("main-background-gradient-color: radial"));
          }

          document.head.appendChild(style);
        }

        if (event.data.type === 'applyFont') {
          if (event.data.font && event.data.font.startsWith("https://fonts.googleapis.com/")) {
            const link = document.createElement('link');
            link.href = event.data.font;
            link.rel = "stylesheet";
            document.head.appendChild(link);
          }
        }

        if (event.data.type === 'applyTitle') {
          document.title = `${event.data.title}`;
        }

        if (event.data.type === 'applyBusinessName') {
          setBusinessName(event.data.businessName);
        }

        if (event.data.type === 'applyCustomerMainPage') {
          setCustomerMainPage(event.data.customerMainPage);
        }

        if (event.data.type === 'applyCustomerCommissions') {
          setHideCustomerCommissions(event.data.hideCustomerCommissions);
        }
        if (event.data.type === 'applyCustomerAffiliate') {
          setHideCustomerAffiliate(event.data.hideCustomerAffiliate);
        }
        if (event.data.type === 'applyCustomerRewards') {
          setHideCustomerRewards(event.data.hideCustomerRewards);
        }
        if (event.data.type === 'applyCustomerSubscriptions') {
          setHideCustomerSubscriptions(event.data.hideCustomerSubscriptions);
        }
        if (event.data.type === 'applyCustomerLicenses') {
          setHideCustomerLicenses(event.data.hideCustomerLicenses);
        }
        if (event.data.type === 'applyCustomerGaming') {
          setHideCustomerGaming(event.data.hideCustomerGaming);
        }

        if (event.data.type === 'applyCustomerPortalDomain') {
          setCustomerPortalDomain(event.data.customerPortalDomain || "");
        }

        if (event.data.type === 'refetchShopInfo') {
          getShopInfo();
        }

      });
    }
  }, [isCustomization, getShopInfo]);

  useEffect(() => {

    if (isCustomization) {
      document.documentElement.classList.remove("dark", "light", "custom");

      if (isCustom && theme) {
        document.documentElement.classList.add("custom");
      } else if (isDark) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.add("light");
      }
    }

  }, [isCustomization, isDark, isCustom, theme?.configuration]);

  return {
    customerPortalDomain,
    businessName,
    css,

    hideCustomerCommissions,
    customerMainPage,
    hideCustomerAffiliate,
    hideCustomerRewards,
    hideCustomerSubscriptions,
    hideCustomerLicenses,
    hideCustomerGaming,

    withGradient,
    isCustom
  };
}

export default useCustomTheme;