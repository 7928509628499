import customerApi from "../../utils/customer-api";
import { IResponse, ILicenseWrapper } from "../../types/types";


export const getLicenses = async(): Promise<IResponse<ILicenseWrapper>> => {
  const res = await customerApi.get<never, IResponse<ILicenseWrapper>>(`customer/products/licensing`);

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }

}

