import React, { ChangeEvent, ReactNode } from "react";
import { ChevronDownIcon } from "../../assets/icons";

import "./index.css";

interface IOptions {
    value: string | number
    label: string
}
interface ISelect {
    options: IOptions[]
    name?: string
    value: string | number
    className?: string
    style?: React.CSSProperties
    size?: "medium" | "large"
    onChange: (a: ChangeEvent<HTMLSelectElement>) => void
}

const Select = ({ options, name, value, className, style = {}, size = 'medium', onChange }: ISelect): ReactNode => {

  const containerClasses = ['select-container'];

  if (className) {
    containerClasses.push(className);
  }

  return (
    <div className={containerClasses.join(' ')} style={style}>
      <select name={name} className={'select ' + size} value={value} onChange={onChange}>
        {options.map(({ label, value }) => (
          <option value={value} key={value}>{label}</option>
        ))}
      </select>
      <ChevronDownIcon />
    </div>
  );
};

export default Select;
