import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import config from "../../../utils/config";

const useCaptchaFallbackHook = (refCaptcha: any) => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const requestWithCaptchaV2 = (onSuccess: any, onError: any) => {

    if(!refCaptcha || !(refCaptcha && refCaptcha.current)) {
      onError("ReCaptchaV2 required params are not initialized");
    }

    const $recaptchaV2 = document.createElement("div");
    $recaptchaV2.id = "auth-recaptchaV2";

    refCaptcha.current.appendChild($recaptchaV2);

    const options = {
      sitekey: config.CAPTCHA.V2,
      size: 'normal',
      theme: 'light',
      callback: (captcha: string) => {
        onSuccess(captcha)
          .then((res: any) => {
            refCaptcha.current.innerHTML = '';
            return res;
          })
          .catch(() => {
            // @ts-ignore
            window.grecaptcha?.reset();
          })
          .finally(() => {
            // @ts-ignore
            window.grecaptcha?.reset();
          })
      },
      'expired-callback': () => {
        // @ts-ignore
        window.grecaptcha.reset();
        onError('reCAPTCHA v2 expired');
      },
      'error-callback': () => {
        // @ts-ignore
        window.grecaptcha.reset();
        onError('reCAPTCHA v2 failed');
      }
    };
    // @ts-ignore
    window.grecaptcha.render("auth-recaptchaV2", options);
  }

  const requestWithCaptchaV3 = (onSuccess: (captcha: string) => void, onError: (error: any) => void, countCaptcha = 0) => {
    if (executeRecaptcha) {
      const captchaRequest = executeRecaptcha("captcha");
      return captchaRequest
        .then((captcha) => {
          // throw { log: { recaptcha_error: true } };
          return onSuccess(captcha);
        })
        .catch((err) => {
          if (err?.log?.recaptcha_error) {
            countCaptcha += 1;
            if (countCaptcha >= 3) {
              requestWithCaptchaV2(onSuccess, onError);
            } else {
              void requestWithCaptchaV3(onSuccess, onError, countCaptcha);
            }
          } else {
            onError(err);
          }
          throw err;
        });
    } else {
      onError('reCAPTCHA v3 is not loaded correctly.');
    }
  }

  return {
    captchaIsReady: !!executeRecaptcha,
    requestWithCaptchaV3,
    requestWithCaptchaV2
  }

}

export default useCaptchaFallbackHook;