import React, { useState, useMemo, ReactNode } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender, PaginationState, SortingState, ColumnDef
} from "@tanstack/react-table";
import Pagination from "./pagination";
import ClearFilters from "./clear-filters";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

import "./index.css";


interface TableProps<TData extends object> {
    isPlaceholder?: boolean
    cursorPointer?: boolean
    data: TData[]
    clearFilters?: () => void
    columns: ColumnDef<TData, unknown>[]
    notFound?: string
    skipNotFound?: boolean
    onClick?: (a: any, b: any) => any
    className?: string
    pageSize?: number
    pageIndex?: number
    setPageIndex?: (i: number) => any
}

const Table = <TData extends object>(props: TableProps<TData>): ReactNode => {

  const { isPlaceholder, cursorPointer, clearFilters, columns, notFound, skipNotFound, onClick, className } = props;

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: props.pageIndex || 0, pageSize: props.pageSize || 10 })
  const [sorting, setSorting] = React.useState<SortingState>([])

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const tableOptions = {
    columns,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data: props.data ?? [],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    pageCount: Math.ceil(props.data.length / pageSize) ?? -1,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  }

  const tableInstance = useReactTable(tableOptions);

  const { t } = useTranslation('translation', { keyPrefix: 'customer.table' });

  const { getHeaderGroups, getRowModel } = tableInstance;

  if (!props.data.length) {
    if(skipNotFound) {
      return null;
    }
    if(clearFilters) {
      return <ClearFilters clearFilters={clearFilters} />;
    } else {
      return (
        <div className="sellix-table-nothing">
          {notFound ? notFound : t('nothingToShow')}
        </div>
      );
    }
  }

  return (
    <div className={`sellix-table-container ${cursorPointer ? 'cursor-pointer' : ''} ${pageSize ? props.data.length > pageSize ? '' : 'skip-pagination' : props.data.length <= 10 ? 'skip-pagination' : ''}`}>

      <div className="sellix-table" style={isPlaceholder ? { overflow: "hidden" } : {}}>
        <table className={className}>
          <thead>
            {getHeaderGroups().map((headerGroup: any) => {

              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => {

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        title={t('shiftForMultiSort') as string}
                        style={{ whiteSpace: "nowrap" }}
                        {...{
                          className: header.column.getCanSort()
                            ? `unselectable cursor-pointer select-none ${header.column?.columnDef?.className}`
                            : `unselectable ${header.column?.columnDef?.className}`,
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {
                          header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )
                        }


                        {header.column.getCanSort() ?
                          <span className="ml-2">
                            {header.column.getIsSorted() === "asc" ? <FontAwesomeIcon icon={faSortDown} style={{ fontSize: ".65rem", top: -1, position: "relative" }} /> : null}
                            {header.column.getIsSorted() === "desc" ? <FontAwesomeIcon icon={faSortUp} style={{ fontSize: ".65rem", top: -1, position: "relative" }} /> : null}
                            {header.column.getIsSorted() === false ? <FontAwesomeIcon icon={faSort} style={{ fontSize: ".65rem", top: -1, position: "relative" }} /> : null}
                          </span>
                          : null}
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>

          <tbody>
            {
              getRowModel().rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                .map((row: any) => {

                  return (
                    <tr key={row.id} onClick={(e) => (onClick ? onClick(row.original, e) : {})}>
                      {row.getVisibleCells().map((cell: any) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>

      <Pagination
        {...tableInstance}
        data={props.data}
        pageSize={pageSize}
        pageIndex={pageIndex}
        initialPageSize={props.pageSize}
        setPagination={setPagination}
        state=""
      />

    </div>
  );
};

export default Table;
