import i18n from "i18next";
import { initReactI18next } from "react-i18next";

async function loadTranslations() {

  const enResponse = await import("../locale/en.json");
  const itResponse = await import("../locale/it.json");
  const esResponse = await import("../locale/es.json");
  const arResponse = await import("../locale/ar_sa.json");
  const deResponse = await import("../locale/de.json");
  const plResponse = await import("../locale/pl.json");
  const frResponse = await import("../locale/fr.json");
  const jaResponse = await import("../locale/ja.json");
  const nlResponse = await import("../locale/nl.json");
  const ptResponse = await import("../locale/pt.json");
  const ruResponse = await import("../locale/ru.json");
  const zhResponse = await import("../locale/zh_cn.json");
  const cnResponse = await import("../locale/zh_cn.json");

  // @ts-ignore
  const enTranslation = enResponse.default;
  const itTranslation = itResponse.default;
  const esTranslation = esResponse.default;
  const arTranslation = arResponse.default;
  const deTranslation = deResponse.default;
  const plTranslation = plResponse.default;
  const frTranslation = frResponse.default;
  const jaTranslation = jaResponse.default;
  const nlTranslation = nlResponse.default;
  const ptTranslation = ptResponse.default;
  const ruTranslation = ruResponse.default;
  const zhTranslation = zhResponse.default;
  const cnTranslation = cnResponse.default;

  const resources = {
    en: {
      translation: enTranslation,
    },
    it: {
      translation: itTranslation,
    },
    es: {
      translation: esTranslation,
    },
    ar: {
      translation: arTranslation,
    },
    ar_sa: {
      translation: arTranslation,
    },
    de: {
      translation: deTranslation,
    },
    pl: {
      translation: plTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
    nl: {
      translation: nlTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
    zh: {
      translation: zhTranslation,
    },
    cn: {
      translation: cnTranslation,
    },
    zh_cn: {
      translation: cnTranslation,
    },
    "zh-cn": {
      translation: cnTranslation,
    }
  };

  void i18n
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: "en",
      lng: "en",
      interpolation: {
        escapeValue: false,
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b']
      }
    });
}

void loadTranslations();


export default i18n;