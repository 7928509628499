import React from "react";
import config from "../../../../../utils/config";
import { FIAT_SYMBOLS, COUNTRIES_LIST } from "@sellix/shared/configs";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo } from "../../../../../types/types";

import "./index.css";

interface TaxProps {
	invoice: IInvoiceInfo
}

const Tax = ({ invoice }: TaxProps) => {

  const { discount_breakdown, currency, country } = invoice;

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.tax" });

  const tax = discount_breakdown && discount_breakdown.tax_log;

  if(!(tax) || !(tax && tax.total_pre_vat_display) || (tax && +tax.vat_percentage === 0)) {
    return null;
  }

  return <div className="info-table with-shadow">

    <div className="info-table-header">{t('title')}</div>

    <div className="info-table-body">
      <div><span>{t('total')}</span><span>{FIAT_SYMBOLS[currency]}{+(tax.total_with_vat_display).toFixed(2)}</span></div>
      <div><span>{t('preVAT')}</span><span>{FIAT_SYMBOLS[currency]}{+(tax.total_pre_vat_display).toFixed(2)}</span></div>
      <div><span>{t('type')}</span><span><div className="ml-auto badge badge-sellix">{tax.type}</div></span></div>
      <div><span>{t('percentage')}</span><span>{tax.vat_percentage}% ({FIAT_SYMBOLS[currency]}{Number(+tax.total_with_vat_display - +tax.total_pre_vat_display).toFixed(2)})</span></div>
      <div><span>{t('country')}</span><span>
        {(country && country !== "N/A") ? <img src={`${config.CDN_FLAGS_URL}${country.toLowerCase()}.svg`} className="flag-icon mr-2" alt="" /> : "N/A"}
        {(country && country !== "N/A") &&
					COUNTRIES_LIST.find(({ value }) => country === value)
          ?
          COUNTRIES_LIST.find(({ value }) => country === value)?.label : ""
        }
      </span></div>
    </div>

  </div>
}

export default Tax;
