import { Moment } from "moment";
import customerApi from "../../utils/customer-api";
import { IResponse, IRewardsAnalytics } from "../../types/types.ts";

export const getRewardsAnalytics = async(start: Moment, end: Moment): Promise<IResponse<IRewardsAnalytics>> => {
  const startDate = start.format("MM/DD/YYYY");
  // BE does not include end day into stat
  const endDate = end.add(1, "days").format("MM/DD/YYYY");

  const params = { from: startDate, to: endDate };
  const response = await customerApi.get<never, IResponse<IRewardsAnalytics>>("/self_customer/rewards", { params });
  try {
    if (response.status === 200) {
      return response;
    } else {
      console.error(response);
      throw response;
    }
  } catch(err) {
    console.error(err);
    throw err;
  }
}