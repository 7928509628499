import api from "../../utils/api";
import { formData } from "../../utils/config";
import { ICustomerEmailCode, ICustomerToken, IResponse } from "../../types/types";

export interface ICustomerEmailCodeProps {
    email: string
    captcha: string
    shop_id?: number | null
}

export interface ICustomerTokenProps {
    email: string
    captcha: string
    shop_id?: number | null
    code: string
    name?: string
    surname?: string
}

export const getCustomerEmailCode = async(data: ICustomerEmailCodeProps) => {
  try {
    if(data.shop_id) {
      return await api.post<never, IResponse<ICustomerEmailCode>>(`/customer/auth/email`, formData(data));
    } else {
      throw new Error("shop_id is required");
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}


export const getCustomerToken = async(data: ICustomerTokenProps) => {
  try {
    return await api.post<never, IResponse<ICustomerToken>>("customer/auth", formData(data));
  } catch (e) {
    console.error(e);
    throw e;
  }

}
