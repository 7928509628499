export const StripeIcon = () => (
  <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="4" fill="#635BFF"/>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8051 13.6318C16.8051 12.706 17.5646 12.35 18.8227 12.35C20.6266 12.35 22.9053 12.8959 24.7093 13.8691V8.29109C22.7392 7.50779 20.7928 7.19922 18.8227 7.19922C14.0042 7.19922 10.7998 9.71527 10.7998 13.9166C10.7998 20.4678 19.8196 19.4234 19.8196 22.248C19.8196 23.3399 18.8701 23.6959 17.5409 23.6959C15.5708 23.6959 13.0548 22.8889 11.0609 21.797V27.4463C13.2684 28.3957 15.4996 28.7992 17.5409 28.7992C22.4781 28.7992 25.8723 26.3544 25.8723 22.1056C25.8486 15.0322 16.8051 16.2902 16.8051 13.6318Z"
      fill="white"
    />
  </svg>
);

export const PayPalIcon = () => (
  <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="4" fill="#27346A"/>
    <g clipPath="url(#clip0_1220_29895)">
      <path
        opacity="0.6"
        d="M13.9208 6.96875C13.91 7.03804 13.8976 7.10888 13.8837 7.18165C13.4048 9.64011 11.7667 10.4894 9.67439 10.4894H8.60911C8.35324 10.4894 8.13763 10.6752 8.09776 10.9276L7.55234 14.3867L7.39789 15.3672C7.37195 15.5329 7.49969 15.6823 7.66692 15.6823H9.55633C9.78007 15.6823 9.97014 15.5197 10.0054 15.2991L10.0239 15.2031L10.3797 12.9455L10.4025 12.8217C10.4374 12.6002 10.6278 12.4377 10.8516 12.4377H11.1341C12.9647 12.4377 14.3977 11.6944 14.8166 9.54372C14.9916 8.64527 14.901 7.89508 14.438 7.36746C14.2979 7.20836 14.1241 7.07636 13.9208 6.96875Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M13.4198 6.76953C13.3467 6.74824 13.2712 6.72888 13.1938 6.71146C13.116 6.69443 13.0362 6.67933 12.9542 6.66617C12.6669 6.61972 12.3522 6.59766 12.0151 6.59766H9.16914C9.09908 6.59766 9.03249 6.61353 8.97288 6.64217C8.84166 6.70527 8.74411 6.82953 8.7205 6.98166L8.11508 10.8162L8.09766 10.9281C8.13753 10.6757 8.35314 10.4899 8.60901 10.4899H9.6743C11.7666 10.4899 13.4048 9.64023 13.8836 7.18217C13.8979 7.1094 13.9099 7.03856 13.9208 6.96927C13.7996 6.90501 13.6684 6.85004 13.5271 6.8032C13.4922 6.79159 13.4562 6.78037 13.4198 6.76953Z"
        fill="white"
      />
      <path
        d="M8.72154 6.98026C8.74516 6.82813 8.84271 6.70387 8.97394 6.64116C9.03394 6.61252 9.10013 6.59665 9.17019 6.59665H12.0161C12.3533 6.59665 12.668 6.61871 12.9552 6.66516C13.0373 6.67832 13.117 6.69342 13.1948 6.71045C13.2723 6.72787 13.3477 6.74723 13.4209 6.76852C13.4573 6.77936 13.4933 6.79058 13.5285 6.80181C13.6698 6.84865 13.801 6.904 13.9222 6.96787C14.0646 6.05936 13.921 5.44077 13.4298 4.88065C12.8883 4.264 11.9108 4 10.6601 4H7.02916C6.77367 4 6.55574 4.18581 6.51625 4.43858L5.00387 14.025C4.97406 14.2147 5.12038 14.3858 5.31161 14.3858H7.55329L8.11612 10.8148L8.72154 6.98026Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1220_29895">
        <rect width="10.0645" height="12" fill="white" transform="translate(5 4)"/>
      </clipPath>
    </defs>
  </svg>
);
