import React from "react";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo } from "../../../../../types/types";

import "./index.css";

interface AffiliateProps {
	invoice: IInvoiceInfo
}

const Affiliate = ({ invoice }: AffiliateProps) => {

  const { affiliate_data } = invoice;
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.affiliates" });

  if(!affiliate_data) {
    return null
  }

  return <div className="info-table with-shadow">

    <div className="info-table-header">{t('title')}</div>

    <div className="info-table-body">
      <div>
        <span>{t('Amount')}</span><span>{FIAT_SYMBOLS[affiliate_data.currency]}{affiliate_data.amount}</span>
      </div>
      <div>
        <span>{t('ID')}</span>
        <span>{affiliate_data.customer_id}</span>
      </div>
      <div>
        <span>{t('created')}</span>
        <span>{affiliate_data.created_at}</span>
      </div>
    </div>

  </div>
}

export default Affiliate;
