import React from "react";
import { SearchIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

import "./index.css";

interface ISearch {
    onChange: (a: string) => void
    search: string
    long?: boolean
    className?: string
}

const Search = ({ onChange, search, long, className }: ISearch) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.shared" });

  return <div className={`screen-search ${long ? 'long' : ''} ${className}`}>
    <SearchIcon />
    <input
      placeholder={`${t('search')}...`}
      className="sellix-input"
      value={search}
      onChange={e => onChange(e.target.value)}
    />
  </div>
}

export default Search;
