import React from "react";
import { Tooltip } from "@sellix/shared/components";
import { ArrowRightIcon, CopyIcon } from "../../../../../assets/icons";
import { orderStatus } from "../../../../../utils/config";
import { useTranslation } from "react-i18next";
import { EStatuses, IInvoiceInfo, IInvoiceProductInfo } from "../../../../../types/types";
import { FIAT_SYMBOLS, GATEWAYS_OPTIONS } from "@sellix/shared/configs";

import "./index.css";

interface ProductProps {
	invoice: IInvoiceInfo
	product: IInvoiceProductInfo
}

const Product = ({ invoice, product }: ProductProps) => {

  const { pay_what_you_want, affiliate_revenue_percent, price_display, status, title, uniqid, unit_price_display, unit_quantity } = product;

  const { type, developer_invoice, developer_title, currency: invoice_currency, status: invoice_status, affiliate_revenue_customer_id, total_display, subscription, quantity, product_id } = invoice;
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.products" });
  const invoiceTranslate = useTranslation("translation", { keyPrefix: 'customer.invoices.filters.statuses' });

  const isCart = type === "SHOPPING_CART";
  const isSubscription = type === "SUBSCRIPTION";
  const isDevelopers = developer_invoice === 1;
  const isUserPrice = pay_what_you_want;
  const hasAffiliate = invoice_status === 'COMPLETED' && affiliate_revenue_customer_id && affiliate_revenue_percent;

  const link = `/product/${product_id}`;

  return <div key={uniqid} className="invoice-details-products-item">
    <div>
      <Tooltip tooltipText="Copy ID" copiedText={product_id || uniqid} successText={t("idCopied") as string} name="invoice-details-copied-2">
        <div className="invoice-details-products-item-copy" >
          <CopyIcon color="" grey={false} />
        </div>
      </Tooltip>
    </div>

    <div>
      <a href={link} rel="noreferrer" target="_blank">
        {isCart ? title : ""}
        {!isCart && isSubscription ? `${t("subscription")} ${subscription.name}` : ""}
        {!isCart && !isSubscription && isDevelopers ? developer_title ? developer_title : (invoice.product ? invoice.product.title : '') : null}
        {!isCart && !isSubscription && !isDevelopers ? (invoice.product ? invoice.product.title : '') : null}
      </a>
    </div>
    <div>{isUserPrice ? "" : FIAT_SYMBOLS[invoice_currency]}{isUserPrice ? t('priceByCustomer') : isDevelopers ? parseFloat(total_display) : parseFloat(isCart ? unit_price_display : price_display)}</div>
    <div>{isCart ? unit_quantity : quantity}</div>
    <div>{hasAffiliate ? `${Number(hasAffiliate)}%` : ""}</div>

    {isCart ? <div>
      <div
        className={`badge badge-${status === "PENDING" ? "pending" : status === "PROCESSED" ? "completed" : "cancelled"}`}>
        {(orderStatus(invoiceTranslate.t)[status as keyof typeof EStatuses] || "").toLowerCase().replaceAll("_", " ")}
      </div>
    </div> : <div />}

    <div>
      <a href={link} target="_blank" rel="noreferrer">
        <ArrowRightIcon />
      </a>
    </div>
  </div>
}

interface ProductListProps {
	invoice: IInvoiceInfo
}

const ProductList = (props: ProductListProps) => {

  const { type, products = [], product, status, affiliate_revenue_customer_id } = props.invoice;
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.products" });

  const isCart = type === "SHOPPING_CART";
  const hasAffiliate = status === 'COMPLETED' && affiliate_revenue_customer_id;

  return <div className="invoice-details-products">

    <div className="invoice-details-products-header">
      <div />
      <div>{t('title')}</div>
      <div>{t('price')}</div>
      <div>{t('quantity')}</div>
      {hasAffiliate ? <div>{t('percent')}</div> : <div />}
      {isCart ? <div>{t('status')}</div> : <div />}
      <div />
    </div>

    {isCart ?
      products?.map((product, key) =>
        <Product product={product} {...props} key={key} />) : product ? <Product product={product} {...props} /> : null
    }
  </div>
}

export default ProductList;
