import { useCallback } from 'react';
import { toastAlert } from "@sellix/shared/components";
import useCaptchaFallbackHook from "./useCaptchaFallbackHook";
import api from "../../../utils/api";
import { formData } from "../../../utils/config";


export const createInvoice = (data: any) => {
  return api.post("/invoices/create", formData(data), { timeout: 60000 })
    .then((res: any) => {
      if (res && res.status === 200) {
        return res
      } else {
        throw res
      }
    })
    .catch(err => {
      throw err
    })
}

export default function useSubmitPayment({
  shopName,
  email,
  setSending,
  onSuccessComponent,
  pay_what_you_want_price,
  currency,
  refCaptcha,
  custom_fields = {},
  isTopUp
}: {
  shopName: string
  email: string
  setSending: any
  onSuccessComponent: any
  pay_what_you_want_price: number
  currency: string
  refCaptcha: any
  custom_fields: any
  isTopUp: boolean
}) {

  const { requestWithCaptchaV3, requestWithCaptchaV2 } = useCaptchaFallbackHook(refCaptcha);

  const handleSubmitPayment = useCallback(() => {

    const onSuccess = (captcha?: string) => {
      setSending(true);

      const data = {
        custom_fields: JSON.stringify({ custom_fields }),
        gateway: "",
        origin: isTopUp ? "CUSTOMER_PORTAL"  : "STOREFRONT",
        email,
        currency,
        balance_store_name: shopName,
        pay_what_you_want_price,
        captcha: captcha || ""
      };

      return createInvoice(data)
        .then((response) => {
          const { data, status, error, message } = response;

          if (status === 200) {
            const { invoice, uniqid } = data;


            if (invoice || uniqid) {
              if (message) {
                toastAlert('success', message);
              }

              if (invoice) {
                onSuccessComponent(invoice);
              } else {
                onSuccessComponent({ uniqid });
              }
            }

          } else {
            toastAlert('error', error || 'Server error!');
          }
        })
        .catch((error) => {

          if(error?.log?.recaptcha_error) {
            requestWithCaptchaV2(onSuccess, onError);
          } else {
            toastAlert('error', error ? (error.error || error.message) : "Server error!");
          }

          toastAlert('error', error ? (error.error || error.message) : 'Server error!');
        })
        .finally(() => setSending(false));
    }

    const onError = (message: string) => toastAlert('error', message);

    void requestWithCaptchaV3(onSuccess, onError)


  }, [shopName, email, currency, pay_what_you_want_price]);

  return handleSubmitPayment;
}
