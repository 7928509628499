import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import i18n from "./utils/i18n";

import "./assets/styles/index.css";


const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
