import React, { ReactNode } from "react";
import { Button } from "@sellix/shared/components";
import { useTranslation } from "react-i18next";
import { MailIcon } from "../../../assets/icons";

import "./index.css";


const Payouts = (): ReactNode => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.payouts" })

  return <>
    <div className="payouts-block">
      <div className="flex flex-col">
        <div className="payouts-block-title">{t('title')}</div>

        <div className="payouts-block-text">
          {t('message')}
        </div>
      </div>

      <a href="/contact" target="_blank">
        <Button><MailIcon/>&nbsp;&nbsp;<>{t('button')}</></Button>
      </a>
    </div>
  </>
}

export default Payouts;
