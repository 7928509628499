import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Cookies from "universal-cookie";
import { config } from "./api";
const cookies = new Cookies();

export const customerApi = axios.create(config);

// @ts-ignore
customerApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  try {
    config.headers.Authorization = config.headers.Authorization = `Bearer ${cookies.get("customerToken")}`
    return config;
  } catch (e) {
    console.error(e);
  }
},

(error) => Promise.reject(error.response)
)

customerApi.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error) => Promise.reject(error.response)
)

export default customerApi;
