import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Radio, Select, Spin, TextInput } from "@sellix/shared/components";
import Modal from "../../../../components/modal";
import { ToastWarning } from "../../../../assets/icons";
import { useParams } from "react-router";
import { toastAlert } from "@sellix/shared/components";
import { cancelBill, getCancelReasons as getCancelReasonsAction } from "../../actions";
import { useTranslation } from "react-i18next";
import { ICancelBill, ICancelReason, IResponse } from "../../../../types/types";
import useMobile from "../../../../hooks/useMobile.ts";


interface Props {
  initializeData: () => void
  openModal: boolean
  closeModal: () => void
  isArrears?: boolean
}

const CancelModal = ({ initializeData, openModal, closeModal, isArrears = false }: Props): ReactNode => {

  const { id } = useParams();
  const currentMode = localStorage.getItem("theme") || "light";
  const isMobile = useMobile();
  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.cancelModal" });
  const { t: tReasons } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.cancelReasons" });
  const [loadingReasons, setLoadingReasons] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [activeReason, setActiveReason] = useState<string>("");
  const [reasonComment, setReasonComment] = useState<string>("");
  const [reasons, setReasons] = useState<ICancelReason[] | null>(null);

  const cancelSubscription = useCallback(async(): Promise<any> => {
    if (!id) {
      return;
    }

    setCanceling(true);
    const reason = (reasons || []).find((r) => r.name === activeReason);
    await cancelBill(id, reason, reasonComment)
      .then((res: IResponse<ICancelBill>) => {
        toastAlert("success", res.message || "Subscription canceled successfully!");
        closeModal();
        initializeData();
      })
      .catch(err => {
        toastAlert("error", (err && err.error) || 'Something went wrong!')
      })
      .finally(() => setCanceling(false));
  }, [reasons, activeReason, reasonComment, id, initializeData]);

  const getCancelReasons = async(): Promise<any> => {
    setLoadingReasons(true);
    await getCancelReasonsAction()
      .then((res: IResponse<ICancelReason[]>) => {
        setReasons(res.data);
      })
      .catch(err => {
        toastAlert("error", (err && err.error) || 'Something went wrong!')
      })
      .finally(() => setLoadingReasons(false));
  }

  const handleReasonChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveReason(e.target.value);
  }, []);

  const handleChangeReasonComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReasonComment(e.target.value);
  }, []);

  useEffect(() => {
    if (openModal && !reasons) {
      void getCancelReasons();
    }
  }, [openModal, reasons]);

  return (
    <Modal
      open={openModal}
      closeModal={closeModal}
      skipClose
      confirm
      wrapClassName="!max-w-[600px] h-[100%] md:h-[initial] !overflow-y-scroll"
    >
      <div className="modal-container flex flex-col gap-2 h-[100%]">
        <div className="modal-icon info mb-[0.5rem] md:mb-[1.5rem]">
          <ToastWarning />
        </div>
        <div className="modal-header mb-0" style={{ textAlign: "center" }}>

          {t('title')}
        </div>
        <div className="modal-description">
          <span data-i18n="[html]content.body">
            {isArrears
              ? (
                <p dangerouslySetInnerHTML={ { __html: t('messageArrears') as string } }/>
              )
              : t('message')}
          </span>
        </div>
        <div className="modal-description mt-4 flex flex-col gap-2 flex-1">
          <label htmlFor="reason" className="sellix-label mb-0 font-bold text-slate-900 dark:text-white text-sm justify-center">
            {t('reason')}:
          </label>
          <div
            className={`max-h-[200px] md:max-h-[400px] overflow-y-scroll ${loadingReasons ? 'flex justify-center' : ''}`}>
            {loadingReasons
              ? <Spin variant={currentMode === "dark" ? "white" : "black"}/>
              : (reasons || []).map((reason, index) => {
                return (
                  <Radio
                    key={reason.id}
                    wrapperStyle="cursor-pointer p-1.5 hover:bg-slate-50 dark:hover:bg-slate-900 rounded-md items-start"
                    className="mt-1"
                    name="cancel_reason_id"
                    value={reason.name}
                    label={tReasons(reason.name)}
                    onChange={handleReasonChange}
                    id={`reason_${reason.id}`}
                    checked={activeReason === reason.name}
                  />
                );
              })
            }
          </div>
          {activeReason === 'OTHER' && (
            <div className="modal-description mt-[0.5rem] md:mt-[1rem] flex flex-col gap-2">
              <TextInput
                multiline={true}
                rows={2}
                name="cancel_reason_comment"
                value={reasonComment}
                onChange={handleChangeReasonComment}
              />
            </div>
          )}
        </div>
        <div className="modal-footer mt-[0.5rem] md:mt-[1rem]">
          <Button variant="secondary" size="large" onClick={closeModal}>{t('closeButton')}</Button>
          <Button
            className="delete"
            variant="warning"
            disabled={!activeReason || (activeReason === "OTHER" && !reasonComment)}
            onClick={cancelSubscription}
          >
            {canceling ? <Spin variant="white"/> : t("confirmButton")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CancelModal;
