import React from "react";
import Table from "../../../../../components/table";
import { FIAT_SYMBOLS, GATEWAYS_OPTIONS } from "@sellix/shared/configs";
import { useTranslation } from "react-i18next";
import { IAddon, IInvoiceInfo } from "../../../../../types/types";
import { CellContext, ColumnDef } from "@tanstack/react-table";

import "./index.css";

interface IAddonsTable {
    loading?: boolean
    invoice: IInvoiceInfo
}

const Addons = ({ loading, invoice }: IAddonsTable) => {

  const { addons = {}, type, product, product_id, products } = invoice;
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.addons" });

  const isCart = type === "SHOPPING_CART";

  // @ts-ignore
  if(!Object.keys(addons).length || loading) {
    return null;
  }

  const columns: ColumnDef<IAddon>[] = [{
    accessorKey: 'product_title',
    header: t('product') as string
  }, {
    accessorKey: 'title',
    header: t('title') as string
  }, {
    accessorKey: 'price',
    header: t('price') as string,
    cell: ({ row: { original } }: CellContext<IAddon, unknown>) => <div>
      {FIAT_SYMBOLS[original.currency]}{original.price}
    </div>
  }];

  let data: any[] = [];

  if((products && products.length) && isCart) {
    products.forEach(({ title, uniqid }) => {
      if(addons?.[uniqid]) {
        addons[uniqid].forEach((a: IAddon) => data.push({
          ...a,
          product_title: title,
          product_uniqid: uniqid,
        }))
      }
    })
  } else {
    data = addons ? addons[product_id].map((a: IAddon) => ({
      ...a,
      product_title: product?.title,
      product_uniqid: product_id,
    })) : [];
  }

  return (
    <div className="info-table with-shadow table">
      <div className="info-table-header">{t('mainTitle')}</div>

      <div className="info-table-body">
        <Table pageSize={5} data={data} columns={columns} />
      </div>
    </div>
  )
}

export default Addons;
