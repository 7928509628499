import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Search from "../../components/search";
import Select from "../../components/select";
import Breadcrumbs from "../../components/breadcrumbs";
import config, { search } from "../../utils/config";
import { getCustomerInvoices } from "./actions";
import InvoicesTable from "./table";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo, IInvoiceInfoBillWrapper, IResponse } from "../../types/types";

import "./index.css";


const FILTERS = [
  'country',
  'coupon_id',
  'crypto_address',
  'crypto_amount',
  'crypto_received',
  'currency',
  'day',
  'developer_return_url',
  'developer_title',
  'gateway',
  'ip',
  'is_vpn_or_proxy',
  'location',
  'month',
  'paypal_email_delivery',
  'paypal_order_id',
  'paypal_payer_email',
  'cashapp_cashtag',
  'cashapp_note',
  'product_id',
  'product_title',
  'product_type',
  'quantity',
  'skrill_sid',
  'status',
  'total_display',
  'uniqid',
  'user_agent',
  'year',
];


const InvoicesList = () => {

  const [loading, setLoading] = useState(true);
  const [customerOrders, setCustomerOrders] = useState<IInvoiceInfo[]>([]);

  const [searchKey, setSearchKey] = useState('');
  const [searchStatus, setSearchStatus] = useState('all');
  const [searchGateway, setSearchGateway] = useState('all');

  const { pathname } = useLocation();

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices" });

  useEffect(() => {
    setLoading(true);
    getCustomerInvoices()
      .then((res: IResponse<IInvoiceInfoBillWrapper>) => setCustomerOrders(res.data.bills))
      .catch(() => {})
      .finally(() => setLoading(false));

  }, [pathname]);


  const filterByStatus = (invoices: IInvoiceInfo[]) => {
    if (searchStatus === 'all') {
      return invoices;
    } else {
      return invoices.filter(({ status }) => status === searchStatus);
    }
  };

  const filterByGateway = (invoices: IInvoiceInfo[]) => {
    if (searchGateway === 'all') {
      return invoices;
    } else {
      return invoices.filter(({ gateway }) => gateway === searchGateway);
    }
  };

  let data = customerOrders;
  if (searchStatus) {
    data = filterByStatus(customerOrders);
  }
  if (searchGateway) {
    data = filterByGateway(data);
  }
  if (searchKey) {
    data = search(data, FILTERS, searchKey);
  }

  const isEmpty = !loading && !customerOrders.length

  return (
    <div className="invoice-list-screen">
      <div className={`screen-header items-start pt-0 ${isEmpty ? 'hidden' : ''}`}>

        <Breadcrumbs title={t('pageTitle')} breadcrumbs={[]} />

        <div className={`screen-filters w-full justify-end gap-2`}>
          <div className="invoice-list-filters" style={{ color: "var(--black5)" }}>
            <Select
              size="large"
              value={searchStatus}
              onChange={e => setSearchStatus(e.target.value)}
              options={config.STATUS_OPTIONS.map(({ label, value }) => ({ label: t(`filters.statuses.${value}`), value }))}
            />
            <Select
              size="large"
              value={searchGateway}
              onChange={e => setSearchGateway(e.target.value)}
              options={config.GATEWAY_OPTIONS.map(({ label, value }) => ({ label: value === 'all' ? t('filters.gateways.all') : label, value }))}
            />
          </div>

          <Search className="" onChange={setSearchKey} search={searchKey}/>
        </div>
      </div>

      <InvoicesTable
        orders={data}
        loading={loading}
        isEmpty={isEmpty}
        clearFilters={() => {
          setSearchStatus("all");
          setSearchGateway("all");
          setSearchKey("")
        }}
      />
    </div>
  );
};

export default InvoicesList;
