import React, { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../provider/AuthContext";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import { useCustomerContext } from "../../../provider/CustomerContext.tsx";
import { Button } from "@sellix/shared/components";
import TopUpModal from "./top-up";
import RedeemModal from "./reedem";

import "./index.css";


export const GiftCardIcon = () => {
  return <svg width="20" height="20" style={{ minWidth: 20 }}  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 6.66675C2.5 6.00371 2.76339 5.36782 3.23223 4.89898C3.70107 4.43014 4.33696 4.16675 5 4.16675H15C15.663 4.16675 16.2989 4.43014 16.7678 4.89898C17.2366 5.36782 17.5 6.00371 17.5 6.66675V13.3334C17.5 13.9965 17.2366 14.6323 16.7678 15.1012C16.2989 15.57 15.663 15.8334 15 15.8334H5C4.33696 15.8334 3.70107 15.57 3.23223 15.1012C2.76339 14.6323 2.5 13.9965 2.5 13.3334V6.66675Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.83594 13.3333L8.33594 10.8333L10.8359 13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.66667 10.8332C6.00917 10.8332 5 10.2732 5 9.58321C5 8.89321 5.5925 8.33321 6.25 8.33321C7.19 8.31655 7.98083 9.30821 8.33333 10.8332C8.68583 9.30821 9.47667 8.31655 10.4167 8.33321C11.0742 8.33321 11.6667 8.89321 11.6667 9.58321C11.6667 10.2732 10.6575 10.8332 10 10.8332H6.66667Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
};

export const TopUpBalanceIcon = () => {
  return <svg width="20" height="20" style={{ minWidth: 20 }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 10.5C6.75 11.7427 8.7645 12.75 11.25 12.75C13.7355 12.75 15.75 11.7427 15.75 10.5C15.75 9.25725 13.7355 8.25 11.25 8.25C8.7645 8.25 6.75 9.25725 6.75 10.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.75 10.5V13.5C6.75 14.742 8.7645 15.75 11.25 15.75C13.7355 15.75 15.75 14.742 15.75 13.5V10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.25 4.5C2.25 5.304 3.108 6.0465 4.5 6.4485C5.892 6.8505 7.608 6.8505 9 6.4485C10.392 6.0465 11.25 5.304 11.25 4.5C11.25 3.696 10.392 2.9535 9 2.5515C7.608 2.1495 5.892 2.1495 4.5 2.5515C3.108 2.9535 2.25 3.696 2.25 4.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.25 4.5V12C2.25 12.666 2.829 13.0875 3.75 13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.25 8.25C2.25 8.916 2.829 9.3375 3.75 9.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
};


const ProfileOverview = () => {

  const { customerProfile } = useAuthContext();
  const { customerInfo } = useCustomerContext();
  const { t } = useTranslation('translation', { keyPrefix: 'customer.profile' });

  const [topUpOpen, setTopUpModal] = useState(false);
  const [redeemModal, setRedeemModal] = useState(false);

  const { shopInfo } = useAuthContext();

  if (!customerProfile) {
    return null;
  }

  return <>

    <TopUpModal isOpen={topUpOpen} close={() => setTopUpModal(false)} />
    <RedeemModal isOpen={redeemModal} close={() => setRedeemModal(false)} />

    <div className="gift-card-title flex gap-4 items-center">{t('title')}</div>
    <div className="info-table with-shadow mt-0">

      <div className="info-table-body">
        <div className="flex items-center">
          <span>{t('balance')}</span>
          <span>
            <div className="flex flex-row md:flex-col items-end md:!items-center justify-end gap-2 md:!gap-4">
              <span className="mr-6 md:mr-0 text-base font-semibold">
                {FIAT_SYMBOLS[customerInfo?.affiliate_revenue_currency as keyof typeof FIAT_SYMBOLS]}{(+(customerInfo?.affiliate_revenue || 0)).toFixed(2)}
              </span>
              {shopInfo?.shop.enable_top_up_balance ? <Button variant="secondary" onClick={() => setTopUpModal(true)}><TopUpBalanceIcon/>&nbsp;&nbsp;&nbsp;{t('topUp.button')}</Button> : null}
              {shopInfo?.shop.enable_gift_cards ? <Button variant="secondary" onClick={() => setRedeemModal(true)}><GiftCardIcon/>&nbsp;&nbsp;&nbsp;{t('giftCard.button')}</Button> : null}
            </div>
          </span>
        </div>

        <div>
          <span>{t('email')}</span>
          <span>{customerProfile.email}</span>
        </div>

        <div>
          <span>{t('name')}</span>
          <span>{customerProfile.name} {customerProfile.surname}</span>
        </div>

        {customerProfile.city || customerProfile.country_code || customerProfile.state || customerProfile.street_address || customerProfile.additional_address_info || customerProfile.postal_code ?
          <div>
            <span>{t('address')}</span>
            <span>{customerProfile.city} {customerProfile.country_code} {customerProfile.state} {customerProfile.street_address} {customerProfile.additional_address_info} {customerProfile.postal_code}</span>
          </div>
          : null}

        <div>
          <span>{t('created')}</span>
          <span>{moment(customerProfile.created_at * 1000).format('DD, MMM YYYY HH:mm')}</span>
        </div>

        <div className="hidden">
          <span>ID</span>
          <span>{customerProfile.id}</span>
        </div>

        {customerProfile.phone || customerProfile.phone_country_code ?
          <div>
            <span>{t('phone')}</span>
            <span>{customerProfile.phone} {customerProfile.phone_country_code}</span>
          </div> : null}
      </div>


    </div>
  </>
}

export default ProfileOverview;
