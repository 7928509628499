import { useCallback, useMemo } from 'react';
import Cookies, { CookieSetOptions } from 'universal-cookie';

function getTopDomain() {
  let topDomain = '';

  if (window.location.hostname.includes('sellix-staging.gg')) {
    topDomain = 'sellix-staging.gg';
  } else if (window.location.hostname.includes('local-test-sellix.com')) {
    topDomain = 'local-test-sellix.com';
  } else if (window.location.hostname.includes('mysellix.io')) {
    topDomain = 'mysellix.io';
  } else {
    topDomain = window.location.hostname
  }

  return topDomain;
}

function useCustomerAuthCookies() {

  const cookies = useMemo(() => new Cookies(), []);
  const isCustomization = window.location.search.includes('CUSTOMIZATION');

  const setCookies = useCallback((data: string, name: string = 'customerToken') => {

    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);

    const options: CookieSetOptions = {
      path: '/',
      expires: expiryDate,
      sameSite: isCustomization ? "none" : "lax" as "none" | "lax",
      secure: !window.location.hostname.includes('local-test-sellix.com'),
    }

    const topDomain: string = getTopDomain();
    const domains: string[] = [`.customer-portal.${topDomain}`, window.location.hostname];

    domains.forEach((domain) => {
      cookies.set(name, data, { domain, ...options });
    });

  }, [cookies]);

  const removeCookies = useCallback((name = 'customerToken') => {
    const options = {
      path: '/',
      expires: new Date(2000, 1, 1, 1, 1, 1, 0),
      sameSite: isCustomization ? "none" : "lax" as "none" | "lax",
      secure: false
    }

    const topDomain = getTopDomain();
    const domains = [`.customer-portal.${topDomain}`, window.location.hostname];

    domains.forEach((domain) => {
      cookies.set(name, '', { domain, ...options });
    })

  }, [cookies]);

  return { setCookies, removeCookies };
}

export default useCustomerAuthCookies;
