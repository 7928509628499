import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toastAlert } from "@sellix/shared/components";
import { Header, Tax, Payouts, Info, Products, Addons, Affiliate, Custom, Goods, Allowance } from "./components";
import { getInvoiceByID, getInvoiceInfo } from "./actions";
import Breadcrumbs from "../../../components/breadcrumbs";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo, IInvoiceInfoWrapper, IResponse } from "../../../types/types";
import { ICustomFields } from "./components/custom";

import "./index.css";


const Invoice = () => {

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState<IInvoiceInfo>();
  const [info, setInfo] = useState<IInvoiceInfo>();
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details" });

  const { id } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    if(invoice && !(invoice.status !== 'COMPLETED' && invoice.status !== 'CUSTOMER_DISPUTE_ONGOING' && invoice.status !== 'REFUNDED')) {
      getInvoiceInfo(invoice.uniqid, invoice.secret)
        .then((res: any) => {
          setInfo(res.data.info);
        })
        .catch(err => {
          toastAlert("error", err ? err.error : '');
          navigate("/invoices");
        })
      // .finally(() => setLoading(false));

    }
  }, [invoice, navigate]);

  useEffect(() => {
    setLoading(true);

    getInvoiceByID(id)
      .then((res: IResponse<IInvoiceInfoWrapper>) => {
        setInvoice(res.data.invoice);
      })
      .catch(err => {
        toastAlert("error", err ? err.error : '');
        navigate("/invoices");
      })
      .finally(() => setLoading(false));
  }, [id, navigate]);


  if (loading) {
    return null;
  }

  if (!invoice) {
    return null;
  }

  const { custom_fields, approved_address } = invoice;

  let custom: ICustomFields[] = [];
  if (custom_fields) {
    if (custom_fields && custom_fields.length) {
      const data = JSON.parse(custom_fields).custom_fields || {};
      custom = Object.keys(data).map(key => ({ field: key, value: data[key] }));
    }
  }

  return (
    <div className="invoice-details mb-0">

      <div className="screen-header pt-0">
        <Breadcrumbs title={invoice.product?.title || ""} breadcrumbs={[{ title: t('breadcrumbs'), link: '/invoices' }, {
          title: id || "",
          link: `/invoices/${id}`
        }]}/>
      </div>

      <div className="invoice-details-header">
        <Header invoice={invoice}/>
        <Products invoice={invoice}/>
      </div>

      <div className="invoice-details-body">
        <div className="invoice-details-body-left">
          <Info invoice={invoice}/>
          <Addons invoice={invoice} loading={loading}/>
          <Custom custom={custom}/>
          <Allowance allowance={approved_address} loading={loading} />
        </div>

        <div className="invoice-details-body-right">
          <Payouts invoice={invoice} loading={loading}/>
          <Tax invoice={invoice}/>
          <Goods info={info} invoice={invoice}/>
          <Affiliate invoice={invoice}/>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
