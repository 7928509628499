import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { usePagination, DOTS, IPaginationOptions } from "./usePagination";
import { ChevronLeftIcon, ChevronRightIcon } from "../../assets/icons";
import { Button } from "@sellix/shared/components";

import "./index.css";

export interface IPagination {
    onPageChange: Dispatch<SetStateAction<number | '...'>>
    totalCount: number
    siblingCount?: number
    currentPage: number
    pageSize: number
    className?: string
}

const Pagination = (props: IPagination): ReactNode => {

  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationOptions: IPaginationOptions = {
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  };

  const currentMode = localStorage.getItem("theme") || "light";

  const paginationRange: (number | '...')[] = usePagination(paginationOptions);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = (): void => {
    const value = currentPage + 1
    onPageChange(value);
  };

  const onPrevious = (): void => {
    const value = currentPage - 1;
    onPageChange(value);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={`sellix-pagination-container ${className}`}>
      <Button variant="secondary" disabled={currentPage === 1} className="sellix-pagination-item" onClick={onPrevious}>
        <ChevronLeftIcon style={{ color: currentMode === "dark" ? "white" : "var(--black9)" }} />
      </Button>

      {paginationRange.map((pageNumber: number | "...") => {
        if (pageNumber === DOTS) {
          return <Button
            key={pageNumber}
            variant="secondary"
            disabled={true}
            className="sellix-pagination-item dots"
          >
            &#8230;
          </Button>;
        }

        return (
          <Button
            key={pageNumber}
            className={`sellix-pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
            onClick={(): void => onPageChange(pageNumber)}
          >
            {pageNumber}
          </Button>
        );
      })}

      <Button variant="secondary" disabled={currentPage === lastPage} className="sellix-pagination-item" onClick={onNext}>
        <ChevronRightIcon style={{ color: currentMode === "dark" ? "white" : "var(--black9)" }} />
      </Button>
    </div>
  );
};

export default Pagination;