import React from "react";
import Table from "../../../../../components/table";
import { useTranslation } from "react-i18next";
import { ICustomFields } from "../../../../invoices/details/components/custom";
import { CellContext, ColumnDef } from "@tanstack/react-table";

import "./index.css";


const Custom = ({ custom }: { custom: ICustomFields[] }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.customFields" });

  if(!custom.length) {
    return null
  }

  const columns: ColumnDef<ICustomFields>[] = [{
    accessorKey: 'field',
    header: t('field') as string,
  }, {
    accessorKey: 'value',
    header: t('value') as string,
    cell: ({ row }: CellContext<ICustomFields, unknown>) => {
      if(row.original.value) {
        if(typeof row.original.value !== "object") {
          return row.original.value;
        } else {
          return JSON.stringify(row.original.value)
        }
      }
    }
  }];

  return <div className="info-table table with-shadow">
    <div className="info-table-header">{t('title')}</div>
    <div className="info-table-body">
      <Table data={custom} columns={columns} />
    </div>
  </div>
}

export default Custom;