import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../provider/AuthContext";
import { Button, Tooltip } from "@sellix/shared/components";
import Empty from "../../../components/empty";
import { GiftCardIcon } from "../overview";
import { getGiftCards as getGiftCardsAction } from "./actions.ts";
import { toastAlert } from "@sellix/shared/components";
import { IGiftCards } from "../../../types/types.ts";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import RedeemModal from "../overview/reedem.tsx";
import moment from "moment";
import { random } from "../../../utils/config.tsx";

import "./index.css";


const GiftCards = () => {

  const { customerProfile } = useAuthContext();
  const { t } = useTranslation('translation', { keyPrefix: 'customer.giftCards' });
  const [redeemModal, setRedeemModal] = useState(false);
  const [activeCode, setActiveCode] = useState("");
  const [giftCards, setGiftCards] = useState<IGiftCards[]>([]);
  const [loading, setLoading] = useState(true);


  const getGiftCards = useCallback(async() => {
    try {
      setLoading(true);
      const res = await getGiftCardsAction();
      const giftCards = res.data.gift_cards ?? [];
      setGiftCards(giftCards);
    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [setGiftCards, setLoading]);

  useEffect(() => {
    void getGiftCards()
  }, [])

  if (!customerProfile) {
    return null;
  }

  return <div className="w-full mb-8 mt-8">
    <div className="gift-card-title flex gap-4 items-center">
      {t("title")}
      <span className="badge badge-sellix" data-tooltip-id="beta">
            Beta
      </span>
    </div>

    {loading && <div className="flex flex-col gap-4">
      {[1, 2, 3].map((k) => {
        return <div className="gift-card" key={k}>
          <div className="flex gap-3 ">
            <div className="gift-card-icon"><GiftCardIcon/></div>

            <div className="flex flex-col gap-1 justify-center">
              <div className="text-base font-[500] leading-5 skeleton skeleton6" style={{ width: random(100, 150) }}/>
              <div className="gift-card-subtext font-normal text-sm  skeleton skeleton4" style={{ width: random(150, 250) }}/>
            </div>
          </div>

          <div className="gift-card-control">
            <div className="gift-card-input">
              <span className="skeleton skeleton6" style={{ width: "100%" }}/>
            </div>
            <Button variant="primary" disabled={true}>
              {t("card.buttonRedeem")}
            </Button>
          </div>

        </div>
      })}
    </div>}

    {!giftCards.length && !loading && <Empty isGiftCards={true}/>}

    {loading || !giftCards.length ? null : <div className="flex flex-col gap-4">
      {giftCards.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))).map((g, index) => {

        const isExpired = moment().isAfter(moment(g.expiration_date));
        const isRedeemed = g.status === "redeemed";

        return <div className="gift-card" key={g.id}>
          <div className="flex gap-3 ">
            <div className="gift-card-icon"><GiftCardIcon/></div>

            <div className="flex flex-col gap-1 justify-center">
              <div className="text-base font-[500] leading-5">{g.amount}{FIAT_SYMBOLS[g.currency]} {t("card.title")}</div>
              <div className="gift-card-subtext font-normal text-sm" style={{ color: "var(--black5)" }}>
                {
                  isRedeemed ?
                    t("card.titleRedeemed", { date: moment(g.redeemed_at).format("DD.MM.YYYY HH:mm"), email: g.redeemed_by || customerProfile.email }) :
                    isExpired ?
                      t("card.titleExpired", { date: moment(g.redeemed_at).format("DD.MM.YYYY HH:mm") }) :
                      t("card.titleRedeem")
                }
              </div>
            </div>
          </div>

          <div className="gift-card-control">
            <div className="gift-card-input">
              <span>{g.code}</span>
              {isRedeemed || isExpired ? null : <Tooltip name={g.code} copiedText={g.code} successText="Code copied" tooltipText="Copy gift code" onlyCopyIcon/>}
            </div>
            <Button
              variant="primary"
              disabled={isRedeemed || isExpired}
              onClick={() => {
                setRedeemModal(true);
                setActiveCode(g.code)
              }}>
              {isExpired ? t("card.buttonExpired") : isRedeemed ? t("card.buttonRedeemed") : t("card.buttonRedeem")}
            </Button>
          </div>

        </div>
      })}

      <RedeemModal isOpen={redeemModal} close={() => setRedeemModal(false)} activeCode={activeCode} getGiftCards={getGiftCards} />
    </div>}

  </div>
}

export default GiftCards;
