import moment, { Moment } from "moment";

export const DATE_RANGES: Record<string, [Moment, Moment, string]> =  {
  "Last 30 days": [moment().subtract(29, "days"), moment(), "daily"],
  "This month": [moment().startOf("month"), moment().endOf("month"), "daily"],
  "Last month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month"), "daily"],
  "This year": [moment().startOf("year"), moment(), "monthly"],
  "Last year": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year"), "monthly"],
  "All time": [moment(new Date("2019-01-01")), moment(new Date().setDate(new Date().getDate() + 1)), "yearly"],
};
