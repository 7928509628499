import React from "react";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo } from "../../../../../types/types";

import "./index.css";


const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


const Goods = ({ invoice, info }: { invoice: IInvoiceInfo, info: any }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.goods" });

  const {
    status,
    product_type
  } = invoice;


  if(!info) {
    return null
  }

  const {
    product = {},
    serials,
    service_text,
    file,
    file_attachment,
    dynamic_response
  } = info;

  const {
    subtype
  } = product;

  const pType = product_type === "SUBSCRIPTION" ? subtype : product_type;
  const isCart = invoice.type === "SHOPPING_CART";
  const products = { ...info };
  delete products.feedback_uniqid;

  if (status !== 'COMPLETED' && status !== 'CUSTOMER_DISPUTE_ONGOING' && status !== 'REFUNDED') {
    return null
  }

  return (
    <div className="info-table with-shadow">
      <div className="info-table-header">{t('title')}</div>

      <div className="info-table-body">
        {isCart && Object.keys(products).length ?

          Object.keys(products).map((id: string, key: number) => {

            const product = info[id];
            const serials = product.serials || null;
            const service_text = product.service_text;
            const file = product.file_attachment;
            const dynamic_response = product.dynamic_response;

            return  <div className="invoice-details-block-goods-cart" key={key}>
              <div className="invoice-details-block-goods-cart-title" style={{ whiteSpace: "nowrap" }}>
                {product.product.title}
              </div>
              {product.product.type === "SERIALS" &&
                                (serials && serials.length ? <pre className="">{serials.map((serial: any) => <div key={serial}>{serial}<br/></div>)}</pre> : <div>{t('noProduct')}</div>)}
              {product.product.type === "SERVICE" &&
                                (service_text ? <pre className=""><div>{service_text}</div></pre> : <div>{t('noProduct')}</div>)}
              {product.product.type === "FILE" &&
                                (file ? <pre className=""><div>{t('fileName')}: {file.original_name}</div><br/><div>Size: {formatBytes(file.size)}</div></pre> : <div>{t('noProduct')}</div>)}
              {product.product.type === "DYNAMIC" &&
                                (dynamic_response ? <pre className=""><div>{dynamic_response}</div></pre> : <div>{t('noProduct')}</div>)}
            </div>
          })

          :

          <div style={{ maxHeight: "20rem", overflowY: "scroll" }}>
            {pType === "SERIALS" &&
                            (serials && serials.length ? <pre>{serials.map((serial: any) => <div key={serial}>{serial}<br/></div>)}</pre> : <div>{t('noProduct')}</div>)}
            {pType === "SERVICE" &&
                            (service_text ? <pre><div>{service_text}</div></pre> : <div>{t('noProduct')}</div>)}
            {pType === "FILE" &&
                            ((file || file_attachment) ? <pre><div>{t('fileName')}: {(file || file_attachment).original_name}</div><br/><div>Size: {formatBytes((file || file_attachment).size)}</div></pre> : <div>{t('noProduct')}</div>)}
            {pType === "DYNAMIC" &&
                            (dynamic_response ? <pre><div>{dynamic_response}</div></pre> : <div>{t('noProduct')}</div>)}
          </div>
        }
      </div>
    </div>
  )
}

export default Goods
