import React, { useMemo, useState } from "react";
import Pagination from "../../components/pagination";
import config from "../../utils/config.tsx";
import ClearFilters from "../../components/table/clear-filters";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IRewardPayload } from "../../types/types";
import RewardValue from "./reward-value.tsx";


const MobileRewards = ({ data, clearFilters }: { data: IRewardPayload[], clearFilters?: () => void }) => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.rewards" });

  const SIZE = 3;

  const [currentPage, setCurrentPage] = useState<number | "...">(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  if(!data.length) {
    if(clearFilters) {
      return <ClearFilters clearFilters={clearFilters} />
    }
    return null;
  }

  return (
    <div>
      {currentTableData.map((item, key) => {
        const { clause, created_at } = item;

        return <div className="table-mobile-card" key={key}>
          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>{t("table.columns.clause")}</div>
                <div>{(config.REWARDS.CLAUSES as Record<string, any>)[clause]?.title}</div>
              </div>
              <div>
                <div>{t("table.columns.action")}</div>
                <div>
                  <RewardValue reward={item}/>
                </div>
              </div>
              <div>
                <div>{t("table.columns.date")}</div>
                <div>
                  {moment(created_at * 1000).utc().format("DD MMM, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobileRewards;
