import { PayPalIcon, StripeIcon } from "./icons.tsx";

export const ICONS = {
  STRIPE: StripeIcon,
  PAYPAL: PayPalIcon,
}

export const TITLES = {
  STRIPE: "Stripe",
  PAYPAL: "PayPal",
}