import React from "react";
import moment from "moment";
import { getAddress, getTransaction, GATEWAYS_OPTIONS } from "@sellix/shared/configs";
import { useTranslation } from "react-i18next";
import { IInvoiceInfo } from "../../../../../types/types";

import "./index.css";

const CRYPTOS = ['BITCOIN', 'LITECOIN', 'TRON', 'ETHEREUM', 'BINANCE_COIN', 'CRONOS', 'POLYGON', 'PLZ', 'USDC', 'USDT', 'BITCOIN_LN', 'CONCORDIUM', 'BITCOIN_CASH', 'NANO', "MONERO", "SOLANA", "RIPPLE"]


const CryptoCard = ({ invoice }: { invoice: IInvoiceInfo }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.payouts" });

  const { gateway, status = "", blockchain, approved_address,
    crypto_address, crypto_received, crypto_amount, crypto_transactions,
    crypto_scheduled_payout, crypto_scheduled_payout_transaction,
    crypto_payout_transaction, crypto_payout
  } = invoice;

  return <>
    <div className="info-table with-shadow">
      <div className="info-table-header">{t('title')}</div>
      <div className="info-table-body">
        <div>
          <span>
            {t('address')}
          </span>
          <span className="flex items-start justify-end">
            {gateway && getAddress({
              gateway,
              address: crypto_address,
              blockchain,
              isEVM: approved_address
            })}
          </span>
        </div>

        {(crypto_amount - crypto_received) > 0 &&
					<div><span>{t('needed')}</span> <span>{(crypto_amount - crypto_received).toFixed(8)}</span></div>
        }
        <div>
          <span>{t('received')}</span> <span>{Number(crypto_received).toFixed(8)}</span>
        </div>
        <div>
          <span>{t('amount')}</span> <span>{Number(crypto_amount).toFixed(8)}</span>
        </div>
      </div>
    </div>

    {!["PENDING"].includes(status) && crypto_transactions && crypto_transactions.length ?
      <div className="info-table">
        <div className="info-table-header">{t('transactions')}</div>
        <div className="info-table-body">
          {crypto_transactions.map((trans, key) =>
            <div key={key}>
              <span>
                {Number(trans.crypto_amount).toFixed(8)}
              </span>
              <span className="flex items-center justify-end gap-2">
                {gateway && getTransaction({
                  gateway,
                  medium: true,
                  tx: trans.hash,
                  blockchain,
                  isEVM: approved_address
                })}
                <img src={gateway && GATEWAYS_OPTIONS[gateway].imgUrl} width="15" alt="Currency" className="mx-1"/>
              </span>
            </div>
          )}
        </div>
      </div>
      : null}

    {crypto_payout && crypto_payout_transaction ?
      <div className="info-table">
        <div className="info-table-header">{t('payout')}</div>
        <div className="info-table-body">
          <div>
            <span>
              {Number(crypto_payout_transaction.crypto_amount).toFixed(8)}
            </span>
            <span className="flex items-center justify-end gap-2">
              {gateway && getTransaction({
                gateway,
                medium: true,
                tx: crypto_payout_transaction.hash,
                blockchain,
                isEVM: approved_address
              })}
              <img src={gateway && GATEWAYS_OPTIONS[gateway].imgUrl} width="15" alt="Currency" className="mx-1"/>
            </span>
          </div>
        </div>
      </div>
      : null}


    {/* {(!crypto_scheduled_payout && status === 'COMPLETED' && !crypto_payout && crypto_transactions) ?*/}
    {/*  <div className="info-table">*/}
    {/*    <div className="info-table-header">{t('scheduled')}</div>*/}
    {/*    <div className="info-table-body px-4 py-4">*/}
    {/*      <div className="d-block" style={{ display: "inline", textAlign: "left", padding: 0 }}>*/}
    {/*        {t('scheduledText1')}<Link style={{ color: "var(--purple6)" }} target="_blank" to="/settings/shop/crypto">{t('here')}</Link>.{t('scheduledText2')}*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  : null}*/}

    {crypto_scheduled_payout && crypto_scheduled_payout_transaction ?
      <div className="info-table">
        <div className="info-table-header">{t('payout')}</div>

        <div className="info-table-body">
          <div>
            <span>{t('amount')}</span>
            <span>{crypto_scheduled_payout_transaction.amount}</span>
          </div>

          <div>
            <span>{t('cut')}</span>
            <span>{crypto_scheduled_payout_transaction.transaction_output}</span>
          </div>

          <div>
            <span>{t('transactionFee')}</span>
            <span>{crypto_scheduled_payout_transaction.transaction_fee}</span>
          </div>

          <div>
            <span>{t('serviceFee')}</span>
            <span>{crypto_scheduled_payout_transaction.service_fee}</span>
          </div>

          <div>
            <span>{t('transaction')}</span>
            <span className="flex items-center justify-end gap-2">
              {gateway && getTransaction({
                gateway,
                medium: true,
                tx: crypto_scheduled_payout_transaction.tx,
                blockchain,
                isEVM: approved_address
              })}
              <img src={gateway && GATEWAYS_OPTIONS[gateway].imgUrl} width="15" alt="Currency" className="mx-1 mb-1"/>
            </span>
          </div>

          <div>
            <span>{t('sent')}</span>
            <span>{moment.unix(crypto_scheduled_payout_transaction.created_at).utc().format("DD MMM - HH:mm UTC")}</span>
          </div>
        </div>

      </div>
      : null }
  </>
}


const Payouts = ({ loading, invoice }: { loading: boolean, invoice: IInvoiceInfo }) => {

  const { total, gateway } = invoice;
  const isFree = parseFloat(total) === 0;

  if(gateway && CRYPTOS.includes(gateway)) {
    if(loading) {
      return null
    } else {
      return (
        <div>
          {!isFree && <CryptoCard invoice={invoice} />}
        </div>
      )
    }
  } else {
    return null
  }
}


export default Payouts
