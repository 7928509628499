import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomerContext } from "../../provider/CustomerContext.tsx";
import { Spin, Button } from "@sellix/shared/components";
import Modal from "../../components/modal";
import { updateCustomerAffiliateLink as updateCustomerAffiliateLinkAction } from "./actions.tsx";
import { toastAlert } from "@sellix/shared/components";

import "./index.css";


const UpdateCustomerAffiliateLinkModal = ({ affiliateLinkHost, isOpen, close }: { isOpen: boolean, close: () => void, affiliateLinkHost: string }) => {

  const { customerInfo, shopInfo } = useCustomerContext();
  const { t } = useTranslation('translation', { keyPrefix: 'customer.profile' });

  const { refetchCustomerInfo } = useCustomerContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const [link, setLink] = useState<any>(customerInfo?.affiliate_custom_link || "");
  const [loading, setLoading] = useState(false);

  const currentMode = localStorage.getItem("theme") || "light";
  const isPending = customerInfo?.affiliate_custom_link_status === "PENDING";
  const isApproved = customerInfo?.affiliate_custom_link_status === "APPROVED";

  useEffect(() => {
    if(isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isOpen]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !(loading || !link)) {
      void updateCustomerAffiliateLink("PENDING");
    }
  };

  const onChangeLink = (e: any) => {
    const link = e.target.value;
    const sanitized = link
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "");
    setLink(sanitized);
  }

  const updateCustomerAffiliateLink = useCallback(async(status: "UNSET" | "PENDING") => {
    try {
      setLoading(true);
      // @ts-ignore
      const res = await updateCustomerAffiliateLinkAction(link, status, customerInfo.id, shopInfo.shop.id);

      if(res.status === 200) {
        refetchCustomerInfo();
        close();
        toastAlert("success", res.message || "");
      } else {
        throw res
      }

    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [link, customerInfo, shopInfo]);

  const isUpdated = (customerInfo?.affiliate_custom_link !== link) && (customerInfo?.affiliate_custom_link_status === "APPROVED");

  return <>

    <Modal open={isOpen} className="sellix-top-up-modal" closeModal={close}>
      <div className="p-8 pb-6 border-b-0">
        <div style={{
          fontSize: 24,
          fontWeight: 500,
          lineHeight: "29px",
          textAlign: "center",
          marginBottom: ".5rem",
          color: "var(--black9)"
        }}>
            Personalize link
        </div>
        <div style={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          textAlign: "center",
          color: "var(--black5)"
        }}>
          {customerInfo?.affiliate_custom_link_reason ? `Your link was rejected, reason: ${customerInfo.affiliate_custom_link_reason}` :
            isPending ?
              "Your request is currently being reviewed by the store owner. You will be notified as soon as it is approved or if further action is required."
              :
              isApproved ?
                "Your link was approved by shop owner." :
                "The link will be accessible once it has been confirmed by the store owner."
          }
          <br/>
        </div>
      </div>
      <form className="p-8 pt-0 pb-0" onSubmit={(e) => e.preventDefault()}>
        <div>
          <label htmlFor="" className="sellix-label">
            {isPending ? null : "Preferred link URL"}
          </label>
          <div className="relative">
            <div
              style={{
                fontSize: 14,
                fontWeight: "var(--input-font-weight)",
                color: "var(--black9)"
              }}
              className="absolute left-4 top-[calc(50%-10px)]"
            >
              /a/
            </div>
            <input
              disabled={isPending}
              ref={inputRef}
              type="text"
              autoFocus
              onKeyDown={handleKeyDown}
              className="sellix-input pl-10"
              placeholder="myref"
              value={link}
              onChange={onChangeLink}
            />
          </div>
        </div>
      </form>
      {
        (isUpdated)
          ?
          <div className="justify-between border-t-0 p-8 pt-6 gap-2 flex">
            <Button onClick={() => {
              setLink(customerInfo.affiliate_custom_link || "");
              close();
            }} variant="secondary" className="w-1/2" size="extra-large">
                Close
            </Button>
            <Button onClick={() => updateCustomerAffiliateLink("PENDING")} className="w-1/2" size="extra-large" disabled={loading}>
              {loading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : !customerInfo.shop.affiliate_custom_link_must_approve ? "Update Custom Link" : "Request New Custom Link"}
            </Button>
          </div> :
          (isPending || isApproved) ?
            <div className="justify-between border-t-0 p-8 pt-6 gap-2 flex">
              <Button onClick={close} variant="secondary" className="w-1/2" size="extra-large">
                  Close
              </Button>
              <Button onClick={() => updateCustomerAffiliateLink("UNSET")} className="w-1/2" size="extra-large" disabled={loading}>
                {loading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : "Revoke"}
              </Button>
            </div>
            :
            <div className="justify-between border-t-0 p-8 pt-6">
              <Button onClick={() => updateCustomerAffiliateLink("PENDING")} className="w-full" size="extra-large" disabled={loading || !link}>
                {loading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : !customerInfo?.shop.affiliate_custom_link_must_approve ? "Apply Custom Link" : "Request Custom Link"}
              </Button>
            </div>
      }
    </Modal>

  </>
}

export default UpdateCustomerAffiliateLinkModal;
