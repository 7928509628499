import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import c from "./config";

export const config: AxiosRequestConfig = {
  baseURL: c.API_ROOT,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 0
  },
  timeout: 30000
}


const api = axios.create(config);

api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  error => Promise.reject(error.response),
)

export default api;
