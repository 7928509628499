import React from "react";
import { useAuthContext } from "../../provider/AuthContext";
import "./index.css";

export const MainLoader = () => {
  return <svg className="loader-circular" viewBox="25 25 50 50">
    <circle className="loader-path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle>
  </svg>
};

const InitialScreen = () => {

  const { isThemeReady } = useAuthContext();

  if(isThemeReady) {
    return null
  }

  return <div className="initial-screen">
    <MainLoader />
  </div>
}

export default InitialScreen;
