import customerApi from "../../utils/customer-api";
import { formData } from "../../utils/config"
import { ICancelBill, ICancelReason, IResponse, ISubscriptionWrapper } from "../../types/types";
import api from "../../utils/api.tsx";

export const getRecurring = async(): Promise<IResponse<ISubscriptionWrapper>> => {
  const res = await customerApi.get<never, IResponse<ISubscriptionWrapper>>(`self_customer/invoices/recurring_bills`);

  if (res.status === 200) {
    return res
  } else {
    console.error(res);
    throw res;
  }
}

export const getRecurringById = async(id?: string): Promise<IResponse<ISubscriptionWrapper>> => {
  const res = await customerApi.get<never, IResponse<ISubscriptionWrapper>>(`invoices_customer/recurring_bill/${id}`)

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const getCancelReasons = async(): Promise<IResponse<ICancelReason[]>> => {
  const res = await customerApi.get<never, IResponse<ICancelReason[]>>(`/dicts/subscription_cancel_reasons`)
  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

interface ICancelBillRequestPayload {
  id: string
  cancel_reason_id?: number
  cancel_reason_comment?: string | null
}

export const cancelBill = async(id: string, reason?: ICancelReason, reasonComment?: string): Promise<IResponse<ICancelBill>> => {
  const data: ICancelBillRequestPayload = { id };
  if (reason) {
    data.cancel_reason_id = reason.id;
    if (reasonComment) {
      data.cancel_reason_comment = reasonComment;
    }
  }
  const res = await customerApi.post<never, IResponse<ICancelBill>>(`invoices_customer/recurring_bill/cancel`, formData(data));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}


export const evmSpenders = async(): Promise<IResponse<any>> => {
  const res = await api.get<never, IResponse<ICancelBill>>(`evm/approved_spenders`);

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}
