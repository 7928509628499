import React, { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Commissions from "../pages/commissions";
import Affiliate from "../pages/affiliate";
import Profile from "../pages/profile";
import Subscriptions from "../pages/subscriptions";
import Subscription from "../pages/subscriptions/details";
import Invoices from "../pages/invoices";
import Invoice from "../pages/invoices/details";
import Licenses from "../pages/licenses";
import Rewards from '../pages/rewards';
import RconGaming from "../pages/rcon-gaming";
import PaymentMethods from '../pages/payment-methods';
import CreatePaymentMethod from "../pages/payment-methods/create.tsx";
import RequireAuth from "../provider/RequireAuth";
import Layout from "./layout";
import CustomerProvider from "../provider/CustomerProvider";
import { useAuthContext } from "../provider/AuthContext";
import i18n from "../utils/i18n";
import { MainLoader } from "../pages/login/initial-screen";

import "./index.css";

const Nullish = () => null;
const DashboardInternal: FC = () => {

  const { loading, customerMainPage } = useAuthContext();

  if (loading || !i18n.isInitialized) {
    return <div className="initial-screen">
      <MainLoader/>
    </div>
  }

  return <>
    <Routes>
      <Route path="commissions" element={<RequireAuth><Layout><Commissions/></Layout></RequireAuth>}/>
      <Route path="affiliate" element={<RequireAuth><Layout><Affiliate/></Layout></RequireAuth>}/>
      <Route path="subscriptions/:id" element={<RequireAuth><Layout><Subscription/></Layout></RequireAuth>}/>
      <Route path="subscriptions" element={<RequireAuth><Layout><Subscriptions/></Layout></RequireAuth>}/>
      <Route path="invoices/:id" element={<RequireAuth><Layout><Invoice/></Layout></RequireAuth>}/>
      <Route path="invoices" element={<RequireAuth><Layout><Invoices/></Layout></RequireAuth>}/>
      <Route path="licenses" element={<RequireAuth><Layout><Licenses/></Layout></RequireAuth>}/>
      <Route path="profile" element={<RequireAuth><Layout><Profile/></Layout></RequireAuth>}>
        <Route index element={<PaymentMethods/>}/>
        <Route path="payment-methods/create" element={<CreatePaymentMethod />} />
        <Route path="*" element={<Nullish />} />
      </Route>
      <Route path="rewards" element={<RequireAuth><Layout><Rewards /></Layout></RequireAuth>} />
      <Route path="rcon-gaming" element={<RequireAuth><Layout><RconGaming /></Layout></RequireAuth>} />
      <Route path="*" element={customerMainPage ? <Navigate to={`/${customerMainPage}`} /> : <Navigate to="/commissions" />} />
    </Routes>
  </>
}

const Dashboard: FC = () => {
  return <CustomerProvider>
    <DashboardInternal />
  </CustomerProvider>
};

export default Dashboard;
