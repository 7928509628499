import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ArrowLeftIcon } from "@sellix/shared/components";
import { SuccessCheckIcon } from "@sellix/shared/components";
import { PayPalIcon, StripeIcon } from "./icons.tsx";
import { useAuthContext } from "../../provider/AuthContext.ts";
import StripePaymentMethod from "./stripe.tsx";
import { PaymentMethodsIcon } from "../../assets/icons.tsx";

const PAYMENT_METHOD_STRIPE = "STRIPE";
const PAYMENT_METHOD_PAYPAL = "PAYPAL";

const CreatePaymentMethod = () => {
  const { t } = useTranslation("translation", { keyPrefix: "customer.paymentMethods" });
  const [paymentMethod, setPaymentMethod] = useState<string>("STRIPE");
  const { shopId, customerProfile } = useAuthContext();
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>();

  const handleGoBack = useCallback(() => {
    // if (paymentMethod) {
    //   setPaymentMethod(undefined);
    // } else {
    //   navigate('/payment-methods');
    // }
    // Uncomment code above when we have more than one payment method gateway
    navigate('/profile');
  }, [navigate, setPaymentMethod]);

  const handleSelectStripe = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setPaymentMethod("STRIPE")
  }, [setPaymentMethod]);

  const handleStatusChange =  useCallback((status: string, info?: string) => {
    setStatus(status);
  }, [setStatus]);

  return (
    <div className="payment-methods-screen">

      <div className="flex justify-between items-center">
        <div style={{ fontSize: 20, fontWeight: 600, color: "var(--black9)" }} className="flex gap-4 items-center">
            Payment Methods
          <span className="badge badge-sellix" data-tooltip-id="beta">
            Beta
          </span>
        </div>
        <Button
          onClick={handleGoBack}
        >
          <ArrowLeftIcon/>&nbsp;&nbsp;&nbsp;{t('buttons.back')}
        </Button>

      </div>

      <div className="payment-methods-screen-container">
        {!paymentMethod && (
          <div className="payment-methods-block payment-methods-cards">
            <div className="payment-methods-card" onClick={handleSelectStripe}>
              <StripeIcon/>
              <span>Stripe</span>
            </div>

            <div className="payment-methods-card hidden" onClick={() => setPaymentMethod("PAYPAL")}>
              <PayPalIcon/>
              <span>PayPal</span>
            </div>
          </div>
        )}

        {shopId && customerProfile?.email && !!paymentMethod && status !== "success" && (
          <div className="payment-methods-block">
            {paymentMethod === PAYMENT_METHOD_STRIPE && (
              <div className="payment-methods-stripe-card">
                <StripePaymentMethod onStatusChange={handleStatusChange} onGoBack={handleGoBack}/>
              </div>
            )}

            {paymentMethod === PAYMENT_METHOD_PAYPAL && (
              <div>
                {t("phrases.comingSoon")}
              </div>
            )}
          </div>
        )}

        {status === "success" && (
          <div className="payment-methods-block payment-methods-setup-message">
            <div className="payment-methods-setup-message-icon">
              <SuccessCheckIcon/>
            </div>
            <div className="payment-methods-setup-message-title">
              {t('phrases.addedSuccessfully')}
            </div>

            <Button
              className="mt-4"
              style={{ fontWeight: 600, padding: "0 .25rem", display: "flex", gap: "0.5rem" }}
              onClick={handleGoBack}
            >
              <ArrowLeftIcon/> {t('buttons.backToList')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreatePaymentMethod;