import customerApi from "../../utils/customer-api";
import { IResponse, IRconProductsWrapper } from "../../types/types";
import { formData } from '../../utils/config.tsx';


export const getRconProducts = async(invoiceId: number | null = null, productId: number | null = null): Promise<IResponse<IRconProductsWrapper>> => {
  const res = await customerApi.post<never, IResponse<IRconProductsWrapper>>(
    `customer/products/rcon_gaming`,
    formData({ invoice_id: invoiceId, product_id: productId }),
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const executeRconProductCommands = async(invoiceId: number, productId: number): Promise<IResponse<IRconProductsWrapper>> => {
  const res = await customerApi.post<never, IResponse<IRconProductsWrapper>>(
    `customer/products/rcon_gaming/execute`,
    formData({ invoice_id: invoiceId, product_id: productId }),
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}