import { useEffect, useState } from "react";

function useMobile() {

  const [isMobile, setMobile] = useState<boolean>(false);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    setMobile(window.innerWidth <= 768);
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return isMobile;
}

export default useMobile;
