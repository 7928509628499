

import React from "react";
import { Tooltip } from "@sellix/shared/components";
import { orderStatus } from "../../../../../utils/config";
import { PureFileIcon } from "../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { EStatuses, ISubscription } from "../../../../../types/types";
import { GATEWAYS_OPTIONS } from "@sellix/shared/configs";

import './index.css';

const InvoiceHeader = ({ bill, tick }: { bill: ISubscription, tick?: number }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.header" });
  const subscriptionTranslate = useTranslation("translation", { keyPrefix: 'customer.subscriptions.filters.statuses' });

  const billId = bill.type === "v2" ? bill.product_subscription_id : bill.id;
  const status = bill.status === "COMPLETED" ? "ENDED" : bill.status;

  return <div className="invoice-details-info" style={{ borderBottom: "none" }}>
    <div>
      <div className="invoice-details-info-image">
        {bill.gateway ? <img src={GATEWAYS_OPTIONS[bill.gateway].imgUrl} alt=""/> : <PureFileIcon />}
      </div>

      <div>
        <div className="flex items-center flex-row gap-1">
          <div style={{ color: "var(--black9)", fontWeight: 500 }} className="flex items-center gap-2 !flex-row">
            {billId} <Tooltip onlyCopyIcon tooltipText="Copy ID" name="invoice-details-info-copy" copiedText={billId} successText={t('idCopied') as string}/>
          </div>
        </div>
        <span className="mt-0">{t('paidInvoices')}: {tick}</span>
      </div>
    </div>

    <div className="flex gap-2">
      <span className={`badge badge-${(status || "").toLowerCase()} ml-2`}>
        {(orderStatus(subscriptionTranslate.t)[status as keyof typeof EStatuses] || "").toLowerCase()}
      </span>
      {bill.approved_address ?
        <Tooltip name="approved" place="left" tooltipText={`The customer has approved Sellix to use the allowance of their ${bill.gateway} funds to automatically pay for recurring invoices.`}>
          <div className="badge badge-confirmation">
              Allowance
          </div>
        </Tooltip>
        : null}
    </div>
  </div>
}


export default InvoiceHeader;
