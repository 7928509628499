import React from "react";
import InvoicesTable from "../../../../invoices/table";
import { useTranslation } from "react-i18next";
import useMobile from "../../../../../hooks/useMobile";
import { IInvoiceInfo } from "../../../../../types/types";

import "./index.css";


const SubscriptionInvoices = ({ invoices }: { invoices: IInvoiceInfo[] }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details" });
  const isMobile = useMobile();

  return <div className="info-table table with-shadow">
    <div className="info-table-header">{t('invoicesTable')}</div>

    <div className={isMobile ? '' : 'info-table-body'}>
      <InvoicesTable orders={invoices} loading={false} isEmpty={!invoices.length} />
    </div>
  </div>
}

export default SubscriptionInvoices;
