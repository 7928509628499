import React, { CSSProperties, ReactNode } from "react";

type IIcon = {
    className?: string
    style?: CSSProperties
    width?: number
    height?: number
    color?: string
    grey?: boolean
}


export const MenuIcon = (): ReactNode => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 6H20" stroke="var(--black9)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M4 12H20" stroke="var(--black9)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M4 18H20" stroke="var(--black9)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const DashboardIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.0002 12.4974C10.9206 12.4974 11.6668 11.7512 11.6668 10.8307C11.6668 9.91025 10.9206 9.16406 10.0002 9.16406C9.07969 9.16406 8.3335 9.91025 8.3335 10.8307C8.3335 11.7512 9.07969 12.4974 10.0002 12.4974Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.2085 9.6224L12.9168 7.91406" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5.33333 16.6682C4.11512 15.6999 3.22826 14.3767 2.79565 12.8819C2.36304 11.3871 2.4061 9.79475 2.91887 8.32552C3.43164 6.85629 4.38873 5.58294 5.65749 4.68193C6.92625 3.78092 8.44386 3.29688 10 3.29688C11.5561 3.29688 13.0737 3.78092 14.3425 4.68193C15.6113 5.58294 16.5684 6.85629 17.0811 8.32552C17.5939 9.79475 17.637 11.3871 17.2044 12.8819C16.7717 14.3767 15.8849 15.6999 14.6667 16.6682H5.33333Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const AffiliateIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.3335 11.6676C8.60506 11.9448 8.9292 12.165 9.28694 12.3153C9.64467 12.4656 10.0288 12.5431 10.4168 12.5431C10.8049 12.5431 11.189 12.4656 11.5467 12.3153C11.9045 12.165 12.2286 11.9448 12.5002 11.6676L15.8335 8.3343C16.386 7.78176 16.6964 7.03237 16.6964 6.25097C16.6964 5.46956 16.386 4.72017 15.8335 4.16763C15.281 3.6151 14.5316 3.30469 13.7502 3.30469C12.9688 3.30469 12.2194 3.6151 11.6668 4.16763L11.2502 4.5843" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.6667 8.33636C11.3951 8.0592 11.0709 7.83901 10.7132 7.68869C10.3555 7.53837 9.97135 7.46094 9.58332 7.46094C9.19529 7.46094 8.81116 7.53837 8.45343 7.68869C8.0957 7.83901 7.77155 8.0592 7.49999 8.33636L4.16666 11.6697C3.61412 12.2222 3.30371 12.9716 3.30371 13.753C3.30371 14.5344 3.61412 15.2838 4.16666 15.8364C4.71919 16.3889 5.46859 16.6993 6.24999 16.6993C7.03139 16.6993 7.78079 16.3889 8.33332 15.8364L8.74999 15.4197" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const PayoutsIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.8335 7.5H7.50016C6.57969 7.5 5.8335 8.24619 5.8335 9.16667V14.1667C5.8335 15.0871 6.57969 15.8333 7.50016 15.8333H15.8335C16.754 15.8333 17.5002 15.0871 17.5002 14.1667V9.16667C17.5002 8.24619 16.754 7.5 15.8335 7.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.6667 13.3333C12.5871 13.3333 13.3333 12.5871 13.3333 11.6667C13.3333 10.7462 12.5871 10 11.6667 10C10.7462 10 10 10.7462 10 11.6667C10 12.5871 10.7462 13.3333 11.6667 13.3333Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1667 7.4974V5.83073C14.1667 5.3887 13.9911 4.96478 13.6785 4.65222C13.366 4.33966 12.942 4.16406 12.5 4.16406H4.16667C3.72464 4.16406 3.30072 4.33966 2.98816 4.65222C2.67559 4.96478 2.5 5.3887 2.5 5.83073V10.8307C2.5 11.2728 2.67559 11.6967 2.98816 12.0092C3.30072 12.3218 3.72464 12.4974 4.16667 12.4974H5.83333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const SubscriptionIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 9.54849C17.7707 7.8987 17.0054 6.37005 15.8218 5.19802C14.6383 4.02599 13.1023 3.2756 11.4503 3.06245C9.79836 2.84929 8.12215 3.18519 6.67988 4.01841C5.23761 4.85163 4.1093 6.13593 3.46875 7.67349M3 4.92349V7.67349H5.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M3 11.4219C3.22927 13.0717 3.99462 14.6003 5.17815 15.7723C6.36168 16.9444 7.89773 17.6948 9.54968 17.9079C11.2016 18.1211 12.8779 17.7852 14.3201 16.952C15.7624 16.1187 16.8907 14.8344 17.5312 13.2969M18 16.0469V13.2969H15.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.3833 8.74806C12.2777 8.56475 12.1241 8.41363 11.9392 8.31088C11.7542 8.20813 11.5448 8.1576 11.3333 8.16472H10.1667C9.85725 8.16472 9.5605 8.28764 9.34171 8.50643C9.12292 8.72522 9 9.02197 9 9.33139C9 9.64081 9.12292 9.93756 9.34171 10.1563C9.5605 10.3751 9.85725 10.4981 10.1667 10.4981H11.3333C11.6428 10.4981 11.9395 10.621 12.1583 10.8398C12.3771 11.0586 12.5 11.3553 12.5 11.6647C12.5 11.9741 12.3771 12.2709 12.1583 12.4897C11.9395 12.7085 11.6428 12.8314 11.3333 12.8314H10.1667C9.9552 12.8385 9.74578 12.788 9.56083 12.6852C9.37587 12.5825 9.22234 12.4314 9.11667 12.2481" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.75 12.8333V14M10.75 7V8.16667V7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const InvoicesIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.6665 2.5V5.83333C11.6665 6.05435 11.7543 6.26631 11.9106 6.42259C12.0669 6.57887 12.2788 6.66667 12.4998 6.66667H15.8332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1665 17.5H5.83317C5.39114 17.5 4.96722 17.3244 4.65466 17.0118C4.3421 16.6993 4.1665 16.2754 4.1665 15.8333V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H11.6665L15.8332 6.66667V15.8333C15.8332 16.2754 15.6576 16.6993 15.345 17.0118C15.0325 17.3244 14.6085 17.5 14.1665 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 5.83594H8.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 10.8359H12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.8335 14.1641H12.5002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const LicensesIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 8.33073C10 8.77276 10.1756 9.19668 10.4882 9.50924C10.8007 9.8218 11.2246 9.9974 11.6667 9.9974C12.1087 9.9974 12.5326 9.8218 12.8452 9.50924C13.1577 9.19668 13.3333 8.77276 13.3333 8.33073C13.3333 7.8887 13.1577 7.46478 12.8452 7.15222C12.5326 6.83966 12.1087 6.66406 11.6667 6.66406C11.2246 6.66406 10.8007 6.83966 10.4882 7.15222C10.1756 7.46478 10 7.8887 10 8.33073Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.4168 9.58594L7.0835 12.9193L8.3335 14.1693" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10 12.5L8.75 11.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const RconGamingIcon = (): ReactNode => (
  <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 5.83203C2.5 5.16899 2.76339 4.53311 3.23223 4.06426C3.70107 3.59542 4.33696 3.33203 5 3.33203H15C15.663 3.33203 16.2989 3.59542 16.7678 4.06426C17.2366 4.53311 17.5 5.16899 17.5 5.83203V7.4987C17.5 8.16174 17.2366 8.79762 16.7678 9.26647C16.2989 9.73531 15.663 9.9987 15 9.9987H5C4.33696 9.9987 3.70107 9.73531 3.23223 9.26647C2.76339 8.79762 2.5 8.16174 2.5 7.4987V5.83203Z"
      stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M12.5 16.6667H5C4.33696 16.6667 3.70107 16.4033 3.23223 15.9344C2.76339 15.4656 2.5 14.8297 2.5 14.1667V12.5C2.5 11.837 2.76339 11.2011 3.23223 10.7322C3.70107 10.2634 4.33696 10 5 10H15"
      stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.83203 6.66797V6.6763" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.83203 13.332V13.3404" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.6667 12.5L15 15H17.5L15.8333 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const ProfileIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10 10.8359C11.3807 10.8359 12.5 9.71665 12.5 8.33594C12.5 6.95523 11.3807 5.83594 10 5.83594C8.61929 5.83594 7.5 6.95523 7.5 8.33594C7.5 9.71665 8.61929 10.8359 10 10.8359Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5.14014 15.7101C5.34639 15.0236 5.76844 14.4219 6.34368 13.9943C6.91891 13.5666 7.61668 13.3357 8.33347 13.3359H11.6668C12.3845 13.3357 13.0831 13.5671 13.6588 13.9958C14.2344 14.4244 14.6564 15.0274 14.8618 15.7151" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const SignOutIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.6667 6.66927V5.0026C11.6667 4.56058 11.4911 4.13665 11.1785 3.82409C10.866 3.51153 10.442 3.33594 10 3.33594H4.16667C3.72464 3.33594 3.30072 3.51153 2.98816 3.82409C2.67559 4.13665 2.5 4.56058 2.5 5.0026V15.0026C2.5 15.4446 2.67559 15.8686 2.98816 16.1811C3.30072 16.4937 3.72464 16.6693 4.16667 16.6693H10C10.442 16.6693 10.866 16.4937 11.1785 16.1811C11.4911 15.8686 11.6667 15.4446 11.6667 15.0026V13.3359" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5.8335 10H17.5002L15.0002 7.5M15.0002 12.5L17.5002 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const MoreIcon = ({ width = 20, height = 20, className, style }: IIcon): ReactNode => <svg width={width} height={height} className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="5.25" cy="10.25" r="1.25" fill="currentColor" />
  <circle cx="10.25" cy="10.25" r="1.25" fill="currentColor" />
  <circle cx="15.25" cy="10.25" r="1.25" fill="currentColor" />
</svg>;

export const ChevronDownIcon = (): ReactNode => <svg style={{ minWidth: 20 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5 7.5L10 12.5L15 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const MailIcon = (): ReactNode => <svg style={{ minWidth: 18 }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V12.75C2.25 13.5784 2.92157 14.25 3.75 14.25H14.25C15.0784 14.25 15.75 13.5784 15.75 12.75V5.25C15.75 4.42157 15.0784 3.75 14.25 3.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2.25 5.25L9 9.75L15.75 5.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
export const ChevronRightIcon = ({ width = 20, height = 20, className = "", style = {} }): ReactNode => <svg width={width} height={height} className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.5 5L12.5 10L7.5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const ChevronLeftIcon = ({ className, style }: IIcon): ReactNode => <svg width={20} height={20} className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.5 5L7.5 10L12.5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const ToastWarning = (): ReactNode => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 9V11M12 15V15.01" stroke="var(--purple6)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M4.99921 19H18.9992C19.3255 18.9977 19.6463 18.9156 19.9336 18.7609C20.2209 18.6061 20.466 18.3834 20.6474 18.1122C20.8289 17.841 20.9412 17.5295 20.9747 17.2049C21.0081 16.8803 20.9616 16.5525 20.8392 16.25L13.7392 4C13.5663 3.6874 13.3127 3.42683 13.005 3.24539C12.6972 3.06394 12.3465 2.96825 11.9892 2.96825C11.632 2.96825 11.2812 3.06394 10.9735 3.24539C10.6657 3.42683 10.4122 3.6874 10.2392 4L3.13921 16.25C3.01915 16.5456 2.97155 16.8656 3.00036 17.1833C3.02918 17.501 3.13359 17.8073 3.30486 18.0764C3.47614 18.3456 3.70932 18.5698 3.98494 18.7305C4.26056 18.8912 4.57061 18.9836 4.88921 19" stroke="var(--purple6)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const CartIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.00001 17.4993C5.92048 17.4993 6.66668 16.7532 6.66668 15.8327C6.66668 14.9122 5.92048 14.166 5.00001 14.166C4.07954 14.166 3.33334 14.9122 3.33334 15.8327C3.33334 16.7532 4.07954 17.4993 5.00001 17.4993Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1667 17.4993C15.0871 17.4993 15.8333 16.7532 15.8333 15.8327C15.8333 14.9122 15.0871 14.166 14.1667 14.166C13.2462 14.166 12.5 14.9122 12.5 15.8327C12.5 16.7532 13.2462 17.4993 14.1667 17.4993Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1667 14.1667H5.00001V2.5H3.33334" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5 4.16602L16.6667 4.99935L15.8333 10.8327H5" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const SubsIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 9.54849C17.7707 7.8987 17.0054 6.37005 15.8218 5.19802C14.6383 4.02599 13.1023 3.2756 11.4503 3.06245C9.79836 2.84929 8.12215 3.18519 6.67988 4.01841C5.23761 4.85163 4.1093 6.13593 3.46875 7.67349M3 4.92349V7.67349H5.75" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M3 11.4238C3.22927 13.0736 3.99462 14.6023 5.17815 15.7743C6.36168 16.9463 7.89773 17.6967 9.54968 17.9099C11.2016 18.123 12.8779 17.7871 14.3201 16.9539C15.7624 16.1207 16.8907 14.8364 17.5312 13.2988M18 16.0488V13.2988H15.25" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.3833 8.75001C12.2777 8.5667 12.1241 8.41559 11.9392 8.31283C11.7542 8.21008 11.5448 8.15956 11.3333 8.16668H10.1667C9.85725 8.16668 9.5605 8.28959 9.34171 8.50839C9.12292 8.72718 9 9.02392 9 9.33334C9 9.64276 9.12292 9.93951 9.34171 10.1583C9.5605 10.3771 9.85725 10.5 10.1667 10.5H11.3333C11.6428 10.5 11.9395 10.6229 12.1583 10.8417C12.3771 11.0605 12.5 11.3573 12.5 11.6667C12.5 11.9761 12.3771 12.2728 12.1583 12.4916C11.9395 12.7104 11.6428 12.8333 11.3333 12.8333H10.1667C9.9552 12.8405 9.74578 12.7899 9.56083 12.6872C9.37587 12.5844 9.22234 12.4333 9.11667 12.25" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.75 12.8333V14M10.75 7V8.16667V7Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const ProductIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.6666 2.5V5.83333C11.6666 6.05435 11.7544 6.26631 11.9107 6.42259C12.067 6.57887 12.2789 6.66667 12.5 6.66667H15.8333" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1666 17.5H5.83329C5.39127 17.5 4.96734 17.3244 4.65478 17.0118C4.34222 16.6993 4.16663 16.2754 4.16663 15.8333V4.16667C4.16663 3.72464 4.34222 3.30072 4.65478 2.98816C4.96734 2.67559 5.39127 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.2754 15.6577 16.6993 15.3451 17.0118C15.0326 17.3244 14.6087 17.5 14.1666 17.5Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 5.83398H8.33333" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 10.834H12.5" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.8334 14.166H12.5" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const ArrowRightIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.5 5L12.5 10L7.5 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;


export const DotsIcon = (): ReactNode => <svg style={{ cursor: "pointer" }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="5.25" cy="10.25" r="1.25" fill="var(--black5)" />
  <circle cx="10.25" cy="10.25" r="1.25" fill="var(--black5)" />
  <circle cx="15.25" cy="10.25" r="1.25" fill="var(--black5)" />
</svg>;

export const PureFileIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.6666 2.5V5.83333C11.6666 6.05435 11.7544 6.26631 11.9107 6.42259C12.067 6.57887 12.2789 6.66667 12.5 6.66667H15.8333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1666 17.5H5.83329C5.39127 17.5 4.96734 17.3244 4.65478 17.0118C4.34222 16.6993 4.16663 16.2754 4.16663 15.8333V4.16667C4.16663 3.72464 4.34222 3.30072 4.65478 2.98816C4.96734 2.67559 5.39127 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.2754 15.6577 16.6993 15.3451 17.0118C15.0326 17.3244 14.6087 17.5 14.1666 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 5.83398H8.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 10.834H12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.8334 14.166H12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const RefreshIcon = (): ReactNode => <svg width="18" style={{ minWidth: 18 }} height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.03748 8.2495C3.22714 6.80255 3.93764 5.47433 5.03598 4.51346C6.13433 3.5526 7.54521 3.02496 9.00453 3.02932C10.4638 3.03369 11.8715 3.56975 12.9641 4.53717C14.0567 5.50459 14.7592 6.83704 14.9403 8.28509C15.1213 9.73314 14.7683 11.1975 13.9475 12.4041C13.1267 13.6107 11.8942 14.4768 10.4809 14.8402C9.06755 15.2037 7.5702 15.0396 6.26913 14.3786C4.96806 13.7177 3.95247 12.6052 3.41248 11.2495M3.03748 14.9995V11.2495H6.78748" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const InvoiceIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.6666 2.5V5.83333C11.6666 6.05435 11.7544 6.26631 11.9107 6.42259C12.067 6.57887 12.2789 6.66667 12.5 6.66667H15.8333" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.1666 17.5H5.83329C5.39127 17.5 4.96734 17.3244 4.65478 17.0118C4.34222 16.6993 4.16663 16.2754 4.16663 15.8333V4.16667C4.16663 3.72464 4.34222 3.30072 4.65478 2.98816C4.96734 2.67559 5.39127 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.2754 15.6577 16.6993 15.3451 17.0118C15.0326 17.3244 14.6087 17.5 14.1666 17.5Z" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 5.83398H8.33333" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M7.5 10.834H12.5" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10.8334 14.166H12.5" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const CopyIcon = ({ color, grey, width = 24, height = 25 }: IIcon): ReactNode => <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 8.5H10C8.89543 8.5 8 9.39543 8 10.5V18.5C8 19.6046 8.89543 20.5 10 20.5H18C19.1046 20.5 20 19.6046 20 18.5V10.5C20 9.39543 19.1046 8.5 18 8.5Z" stroke={color ? color : grey ? "var(--black5)" : "var(--purple6)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M16 8.5V6.5C16 5.96957 15.7893 5.46086 15.4142 5.08579C15.0391 4.71071 14.5304 4.5 14 4.5H6C5.46957 4.5 4.96086 4.71071 4.58579 5.08579C4.21071 5.46086 4 5.96957 4 6.5V14.5C4 15.0304 4.21071 15.5391 4.58579 15.9142C4.96086 16.2893 5.46957 16.5 6 16.5H8" stroke={color ? color : grey ? "var(--black5)" : "var(--purple6)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export const AddCircular = ({ width = 20, height = 20, className, style }: IIcon): ReactNode => <svg width={width} height={height} className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M7.5 10H12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M10 7.5V12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const InfoIcon = ({ width = 20, height = 20, className, style }: IIcon): ReactNode => <svg width={width} height={height} className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M10 6.66666H10.0083" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M9.16675 10H10.0001V13.3333H10.8334" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const SearchIcon = (): ReactNode => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.80469 13.1992C10.7042 13.1992 13.0547 10.8487 13.0547 7.94922C13.0547 5.04972 10.7042 2.69922 7.80469 2.69922C4.90519 2.69922 2.55469 5.04972 2.55469 7.94922C2.55469 10.8487 4.90519 13.1992 7.80469 13.1992Z" stroke="var(--black5)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M15.4594 14.85L11.8594 11.25" stroke="var(--black5)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const RewardsIcon = (): ReactNode => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
  />
  <path
    d="M10.0028 12.308L7.53054 13.6079L8.0028 10.8548L6 8.90527L8.76387 8.50471L10 6L11.2361 8.50471L14 8.90527L11.9972 10.8548L12.4695 13.6079L10.0028 12.308Z"
    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
  />
</svg>;


export const EmailIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.8333 4.16602H4.16667C3.24619 4.16602 2.5 4.91221 2.5 5.83268V14.166C2.5 15.0865 3.24619 15.8327 4.16667 15.8327H15.8333C16.7538 15.8327 17.5 15.0865 17.5 14.166V5.83268C17.5 4.91221 16.7538 4.16602 15.8333 4.16602Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2.5 5.83398L10 10.834L17.5 5.83398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const PhoneIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_7146_87201)">
    <path d="M4.16667 3.33398H7.5L9.16667 7.50065L7.08333 8.75065C7.9758 10.5603 9.44039 12.0249 11.25 12.9173L12.5 10.834L16.6667 12.5007V15.834C16.6667 16.276 16.4911 16.6999 16.1785 17.0125C15.866 17.3251 15.442 17.5007 15 17.5007C11.7494 17.3031 8.68346 15.9227 6.38069 13.62C4.07792 11.3172 2.69754 8.25126 2.5 5.00065C2.5 4.55862 2.67559 4.1347 2.98816 3.82214C3.30072 3.50958 3.72464 3.33398 4.16667 3.33398Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_7146_87201">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>;

export const LocationIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 11.666C11.3807 11.666 12.5 10.5467 12.5 9.16602C12.5 7.7853 11.3807 6.66602 10 6.66602C8.61929 6.66602 7.5 7.7853 7.5 9.16602C7.5 10.5467 8.61929 11.666 10 11.666Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.7142 13.8806L11.1784 17.4164C10.8659 17.7286 10.4422 17.904 10.0005 17.904C9.55872 17.904 9.13506 17.7286 8.82254 17.4164L5.28588 13.8806C4.35356 12.9482 3.71866 11.7603 3.46145 10.4671C3.20424 9.17394 3.33629 7.83352 3.84088 6.61536C4.34547 5.39721 5.19996 4.35604 6.29628 3.62351C7.3926 2.89098 8.68152 2.5 10 2.5C11.3186 2.5 12.6075 2.89098 13.7038 3.62351C14.8001 4.35604 15.6546 5.39721 16.1592 6.61536C16.6638 7.83352 16.7958 9.17394 16.5386 10.4671C16.2814 11.7603 15.6465 12.9482 14.7142 13.8806Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;


export const LangIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 20, color: "var(--black5)", marginRight: "0" }}>
  <path d="M3.33203 4.16406H9.16536" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M7.4987 2.5V4.16667C7.4987 7.84833 5.63286 10.8333 3.33203 10.8333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M4.16797 7.5C4.16547 9.28667 6.62797 10.7567 9.7513 10.8333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M10 16.6641L13.3333 9.16406L16.6667 16.6641" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M15.9167 15H10.75" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>;

export const ThemeIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M10 2.5V17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M10 7.5L13.875 3.625" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M10 11.9167L16.1417 5.77499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
  <path d="M10 16.3333L17.375 8.95833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>;

export const PaymentMethodsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 1024 1024" className="icon" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M896 324.096c0-42.368-2.496-55.296-9.536-68.48a52.352 52.352 0 00-22.144-22.08c-13.12-7.04-26.048-9.536-68.416-9.536H228.096c-42.368 0-55.296 2.496-68.48 9.536a52.352 52.352 0 00-22.08 22.144c-7.04 13.12-9.536 26.048-9.536 68.416v375.808c0 42.368 2.496 55.296 9.536 68.48a52.352 52.352 0 0022.144 22.08c13.12 7.04 26.048 9.536 68.416 9.536h567.808c42.368 0 55.296-2.496 68.48-9.536a52.352 52.352 0 0022.08-22.144c7.04-13.12 9.536-26.048 9.536-68.416V324.096zm64 0v375.808c0 57.088-5.952 77.76-17.088 98.56-11.136 20.928-27.52 37.312-48.384 48.448-20.864 11.136-41.6 17.088-98.56 17.088H228.032c-57.088 0-77.76-5.952-98.56-17.088a116.288 116.288 0 01-48.448-48.384c-11.136-20.864-17.088-41.6-17.088-98.56V324.032c0-57.088 5.952-77.76 17.088-98.56 11.136-20.928 27.52-37.312 48.384-48.448 20.864-11.136 41.6-17.088 98.56-17.088H795.84c57.088 0 77.76 5.952 98.56 17.088 20.928 11.136 37.312 27.52 48.448 48.384 11.136 20.864 17.088 41.6 17.088 98.56z"
    />
    <path fill="currentColor" d="M64 320h896v64H64v-64zm0 128h896v64H64v-64zm128 192h256v64H192z"/>
  </svg>
)