import { useMemo } from "react";
import useMobile from "../../hooks/useMobile";

export const DOTS = '...' as string;

export interface IPaginationOptions {
    totalCount: number
    siblingCount: number
    currentPage: number
    pageSize: number
}

const range = (start: number, end: number): any => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: IPaginationOptions): any => {

  const isMobile = useMobile();

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = (isMobile ? 2 : 3) + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      if(isMobile) {
        return [...leftRange, totalPageCount];
      } else {
        return [...leftRange, DOTS, totalPageCount];
      }
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = (isMobile ? 2 : 3) + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );

      if(isMobile) {
        return [firstPageIndex, ...rightRange];
      } else {
        return [firstPageIndex, DOTS, ...rightRange];
      }
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);

      if(isMobile) {
        return [firstPageIndex, ...middleRange, lastPageIndex];
      } else {
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
      }
    }

  }, [totalCount, pageSize, siblingCount, currentPage, isMobile]);

  return paginationRange as (number | '...')[];
};