import React from "react";
import { Link } from "react-router-dom";
import { CRYPTO_CONTRACTS, GATEWAYS_OPTIONS, getAddress, getTransaction } from "@sellix/shared/configs";


import "./index.css";


const Allowance = ({ allowance }: any) => {

  if(!allowance) {
    return null
  }

  {/* @ts-ignore*/}
  const decimals = CRYPTO_CONTRACTS[allowance.blockchain][allowance.coin].decimals;

  return <div className="info-table with-shadow">

    <div className="info-table-header">EVM Allowance Info</div>

    <div className="info-table-body">
      <div>
        <span>Address</span>
        <span>
          {getAddress({ gateway: allowance.coin, address: allowance.address, blockchain: allowance.blockchain, isEVM: true })}
        </span>
      </div>
      <div>
        <span>Allowance Transaction</span>
        <span className="flex items-center justify-end gap-2">
          {/* @ts-ignore*/}
          {getTransaction({ medium: true, gateway: allowance.coin, tx: allowance.tx, blockchain: allowance.blockchain, isEVM: true })}
          {/* @ts-ignore*/}
          <img src={GATEWAYS_OPTIONS[allowance.coin].imgUrl} width="15" alt="Currency" className="mx-1 mb-1"
            style={{ display: "inline" }}/>
        </span>
      </div>
      <div>
        <span>Allowance</span>
        <span>{allowance.allowance?.toString().slice(0, -decimals)}</span>
      </div>
      <div>
        <span>Subscription</span>
        <span><Link to={`/subscriptions/${allowance.recurring_billing_id}`}>{allowance.recurring_billing_id}</Link></span>
      </div>
    </div>

  </div>
}

export default Allowance;
