import customerApi from '../utils/customer-api';
import api from '../utils/api';
import { ICustomerInfo, ICustomerTheme, IResponse } from "../types/types";
import { IShopInfo } from "../types/types";

export const getCustomerInfo = async(shopId: number): Promise<IResponse<ICustomerInfo>> => {
  try {
    return await customerApi.get<never, IResponse<ICustomerInfo>>(`shop/${shopId}/customers/dashboard`);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getCustomerTheme = async(shopId: number): Promise<IResponse<ICustomerTheme>> => {
  try {
    return await customerApi.get<never, IResponse<ICustomerTheme>>(`/internal/${shopId}/themes/schema`, {
      params: {
        include_files: false,
        include_menus: false,
        include_page: false,
        is_active: true
      }
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getGeneralShopInfo = async(name: string): Promise<IResponse<IShopInfo>> => {
  try {
    return await api.get<never, IResponse<IShopInfo>>(`/shops/domain/${name}`);
  } catch (e) {
    console.error(e);
    throw e;
  }

}


export const getCustomerProfile = async() => {
  const res = await customerApi.get(`/self_customer`);
  if (res.status === 200) {
    return res
  } else {
    console.error(res);
    throw res
  }
}
