import React from "react";
import { useAuthContext } from "../../provider/AuthContext";
import { Outlet } from "react-router-dom";
import Overview from "./overview";
import GiftCards from "./gift-cards";

import "./index.css";


const Profile = () => {

  const { customerProfile, shopInfo } = useAuthContext();

  if(!customerProfile) {
    return null;
  }

  return <div className="profile-screen">

    <div className="w-full">

      <Overview />
      <Outlet />
      {shopInfo?.shop.enable_gift_cards ? <GiftCards /> : null}

    </div>
  </div>
}

export default Profile;
