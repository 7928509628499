import React, { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { StripeSetupIntent } from "@sellix/shared/checkout";
import { toastAlert } from "@sellix/shared/components";
import config from "../../utils/config.tsx";
import { stripeCreateSetupIntent, stripeRefreshSetupIntent } from "./actions.ts";
import { useAuthContext } from "../../provider/AuthContext.ts";

interface Props {
  onStatusChange: (status: string, info?: string) => void
  onGoBack: () => void
}

const StripePaymentMethod: React.FC<Props> = ({ onStatusChange, onGoBack }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { shopId, customerProfile } = useAuthContext();
  const currentMode = localStorage.getItem("theme") || "light";
  const [creating, setCreating] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [stripeSetupIntent, setStripeSetupIntent] = useState();
  const [stripeStatus, setStripeStatus] = useState("");

  const createStripeSetupIntent = useCallback(async(shopId: string | number, customerEmail: string) => {
    if (!executeRecaptcha) {
      return;
    }
    try {
      setCreating(true);
      const captcha = await executeRecaptcha("createCode");
      const response: any = await stripeCreateSetupIntent(shopId, customerEmail, captcha);
      setStripeSetupIntent(response);
    } finally {
      setCreating(false);
    }
  }, [executeRecaptcha]);

  const refreshStripeSetupIntent = useCallback(async(
    shopId: string | number, customerEmail: string, title: string, setupIntentId: string
  ) => {
    if (!executeRecaptcha) {
      return;
    }

    try {
      setRefreshing(true);
      const captcha = await executeRecaptcha("createCode");
      const response: any = await stripeRefreshSetupIntent(shopId, customerEmail, setupIntentId, captcha, title);
      setStripeSetupIntent(response);
    } finally {
      setRefreshing(false);
    }
  }, [executeRecaptcha]);

  const onMessage = useCallback((type: string, message: string) => {
    toastAlert(type as "success" | "error" | "warning" | "info", message);
  }, []);

  const handleStatusChange = useCallback((status: string, info?: string) => {
    setStripeStatus(status);
    onStatusChange(status, info);
  }, [onStatusChange]);

  if (!shopId || !customerProfile?.email) {
    return null;
  }

  return (
    <StripeSetupIntent
      isDark={currentMode === "dark"}
      stripePublicKey={config.STRIPE.PUBLIC_KEY}
      shopId={shopId}
      customerEmail={customerProfile.email}
      // returnUrl?: string;
      setupIntent={stripeSetupIntent}
      createSetupIntent={createStripeSetupIntent}
      refreshSetupIntent={refreshStripeSetupIntent}
      onMessage={onMessage}
      onStatusChange={handleStatusChange}
      onGoBack={onGoBack}
    />
  )
}

export default StripePaymentMethod;