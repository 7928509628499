import React, { ReactNode, useEffect } from "react";
import Login from "../pages/login";
import { useAuthContext } from "./AuthContext";
import { useLocation, useNavigate } from "react-router-dom";


const RequireAuth = ({ children }: { children: ReactNode }) => {

  const { isLoggedIn } = useAuthContext();
  const { search } = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if(!isLoggedIn) {
      const searchParams = new URLSearchParams(search);
      if (searchParams.toString().includes("CUSTOMIZATION")) {
        navigate(`/auth${search}`, { replace: true });
      } else {
        navigate("/auth", { replace: true });
      }
      navigate("/auth", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  if(!isLoggedIn) {
    return <Login />
  }

  return children;
}

export default RequireAuth;