import customerApi from "../../utils/customer-api";
import { IInvoiceInfoBillWrapper, IResponse } from "../../types/types";


export const getCustomerInvoices = async(): Promise<IResponse<IInvoiceInfoBillWrapper>> => {
  const res = await customerApi.get<never, IResponse<IInvoiceInfoBillWrapper>>(`/self_customer/invoices`);
  if (res.status === 200) {
    return res
  } else {
    console.error(res);
    throw res
  }
}
