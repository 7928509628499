import React, { MouseEventHandler, ReactNode } from "react";
import { Button } from "@sellix/shared/components";
import { useTranslation } from "react-i18next";

import "./index.css";


interface ITableClearFilters {
    clearFilters: MouseEventHandler<HTMLButtonElement>
}

const ClearFilters = ({ clearFilters }: ITableClearFilters): ReactNode => {

  const { t } = useTranslation('translation', { keyPrefix: 'customer.table' });

  return <div className="sellix-table-clear">
    <div className="sellix-table-clear-icon">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.6722 14.6667C11.8939 14.6667 14.5055 12.055 14.5055 8.83333C14.5055 5.61167 11.8939 3 8.6722 3C5.45054 3 2.83887 5.61167 2.83887 8.83333C2.83887 12.055 5.45054 14.6667 8.6722 14.6667Z" stroke="var(--purple6)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.1777 16.5L13.1777 12.5" stroke="var(--purple6)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
    <div className="sellix-table-clear-title">{t('nothing.title')}</div>
    <div className="sellix-table-clear-text">{t('nothing.message')}</div>

    <Button onClick={clearFilters}>{t('nothing.button')}</Button>
  </div>
}

export default ClearFilters;
